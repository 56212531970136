<div class="app-header">
  <div class="logo cursor-pointer">
    <app-image
      (click)="goToHome()"
      url="/assets/images/webp/logo.webp"
    />
  </div>
  <div class="menu-options flex flex-row gap-[1rem]">
    <app-translation-selector
    *ngIf="displayTranslationSelector"
    />
    <ng-container
      *ngFor="let menuItem of menuOptions"
    >
      <ng-container
        *ngIf="menuItem.children; else singleMenu"
      >
        <app-icon
          [matMenuTriggerFor]="menu"
          cssClass="profile-icon"
          icon="person_outline"
        />
        <mat-menu
          #menu="matMenu"
          class="user-menu"
        >
          <button
            (click)="submenu.handleClick($event)"
            *ngFor="let submenu of menuItem.children"
            mat-menu-item
          >
            <div
              class="flex flex-row items-center justify-center"
            >
              <app-icon
                [icon]="submenu.icon"
              />
              <span>
            {{ submenu.label }}
          </span>
            </div>
          </button>
        </mat-menu>
      </ng-container>
      <ng-template #singleMenu>
        <app-icon
          (click)="menuItem.handleClick()"
          [icon]="menuItem.icon"
          cssClass="profile-icon"
        />
      </ng-template>
    </ng-container>

  </div>
</div>

<div class="app-content relative">
  <router-outlet></router-outlet>
  <ng-content></ng-content>
</div>

<div class="app-footer">
</div>
