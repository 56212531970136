/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[create edite copy survey version page] clear state';
export const ClearState = createAction(CLEAR_STATE);


/******** FETCH CLIENTS LIST ***********/
const FETCH_CLIENTS_LIST_REQUEST = '[create edite copy survey version page] fetch clients list request';
export const FetchClientsListRequest = createAction(FETCH_CLIENTS_LIST_REQUEST);

const FETCH_CLIENTS_LIST_SUCCESS = '[create edite copy survey version page] fetch clients list success';
export const FetchClientsListSuccess = createAction(FETCH_CLIENTS_LIST_SUCCESS, props<any>());

const FETCH_CLIENTS_LIST_FAILURE = '[create edite copy survey version page] fetch clients list failure';
export const FetchClientsListFailure = createAction(FETCH_CLIENTS_LIST_FAILURE);

/******** FETCH SCHOOL LIST ***********/
const FETCH_SCHOOLS_LIST_REQUEST = '[create edite copy survey version page] fetch schools list request';
export const FetchSchoolsListRequest = createAction(FETCH_SCHOOLS_LIST_REQUEST, props<{ payload: any }>());

const FETCH_SCHOOLS_LIST_SUCCESS = '[create edite copy survey version page] fetch school list success';
export const FetchSchoolsListSuccess = createAction(FETCH_SCHOOLS_LIST_SUCCESS, props<any>());

const FETCH_SCHOOLS_LIST_FAILURE = '[create edite copy survey version page] fetch schools list failure';
export const FetchSchoolsListFailure = createAction(FETCH_SCHOOLS_LIST_FAILURE);

/******** FETCH SCHOOL YEARS ***********/
const FETCH_SCHOOL_YEARS_REQUEST = '[create edite copy survey version page] fetch school years request';
export const FetchSchoolYearsRequest = createAction(FETCH_SCHOOL_YEARS_REQUEST, props<{ payload: any }>());

const FETCH_SCHOOL_YEARS_SUCCESS = '[create edite copy survey version page] fetch school years success';
export const FetchSchoolYearsSuccess = createAction(FETCH_SCHOOL_YEARS_SUCCESS, props<any>());

const FETCH_SCHOOL_YEARS_FAILURE = '[create edite copy survey version page] fetch school years failure';
export const FetchSchoolYearsFailure = createAction(FETCH_SCHOOL_YEARS_FAILURE);

/******** FETCH TIME PERIOD ***********/
const FETCH_TIME_PERIOD_REQUEST = '[create edite copy survey version page] fetch time period request';
export const FetchTimePeriodRequest = createAction(FETCH_TIME_PERIOD_REQUEST, props<{ payload: any }>());

const FETCH_TIME_PERIOD_SUCCESS = '[create edite copy survey version page] fetch time period success';
export const FetchTimePeriodSuccess = createAction(FETCH_TIME_PERIOD_SUCCESS, props<any>());

const FETCH_TIME_PERIOD_FAILURE = '[create edite copy survey version page] fetch time period failure';
export const FetchTimePeriodFailure = createAction(FETCH_TIME_PERIOD_FAILURE);

/******** FETCH STAKEHOLDER LIST ***********/
const FETCH_STAKEHOLDER_LIST_REQUEST = '[create edite copy survey version page] fetch stakeholder list request';
export const FetchStakeholderListRequest = createAction(FETCH_STAKEHOLDER_LIST_REQUEST, props<{ payload: any }>());

const FETCH_STAKEHOLDER_LIST_SUCCESS = '[create edite copy survey version page] fetch stakeholder list success';
export const FetchStakeholderListSuccess = createAction(FETCH_STAKEHOLDER_LIST_SUCCESS, props<any>());

const FETCH_STAKEHOLDER_LIST_FAILURE = '[create edite copy survey version page] fetch stakeholder list failure';
export const FetchStakeholderListFailure = createAction(FETCH_STAKEHOLDER_LIST_FAILURE);

/******** FETCH GRADE QUESTION LIST ***********/
const FETCH_GRADE_QUESTION_LIST_REQUEST = '[create edite copy survey version page] fetch grade question list request';
export const FetchGradeQuestionListRequest = createAction(FETCH_GRADE_QUESTION_LIST_REQUEST, props<any>());

const FETCH_GRADE_QUESTION_LIST_SUCCESS = '[create edite copy survey version page] fetch grade question list success';
export const FetchGradeQuestionListSuccess = createAction(FETCH_GRADE_QUESTION_LIST_SUCCESS, props<any>());

const FETCH_GRADE_QUESTION_LIST_FAILURE = '[create edite copy survey version page] fetch grade question list failure';
export const FetchGradeQuestionListFailure = createAction(FETCH_GRADE_QUESTION_LIST_FAILURE);

/******** FETCH MEASURES LIST ***********/
const FETCH_MEASURES_LIST_REQUEST = '[create edite copy survey version page] fetch measures list request';
export const FetchMeasuresListRequest = createAction(FETCH_MEASURES_LIST_REQUEST, props<any>());

const FETCH_MEASURES_LIST_SUCCESS = '[create edite copy survey version page] fetch measures list success';
export const FetchMeasuresListSuccess = createAction(FETCH_MEASURES_LIST_SUCCESS, props<any>());

const FETCH_MEASURES_LIST_FAILURE = '[create edite copy survey version page] fetch measures list failure';
export const FetchMeasuresListFailure = createAction(FETCH_MEASURES_LIST_FAILURE);

/******** FETCH SURVEY VERSION METADATA ***********/
const FETCH_SURVEY_VERSION_METADATA_REQUEST = '[create edite copy survey version page] fetch survey version metadata request';
export const FetchSurveyVersionMetadataRequest = createAction(FETCH_SURVEY_VERSION_METADATA_REQUEST, props<any>());

const FETCH_SURVEY_VERSION_METADATA_SUCCESS = '[create edite copy survey version page] fetch survey version metadata success';
export const FetchSurveyVersionMetadataSuccess = createAction(FETCH_SURVEY_VERSION_METADATA_SUCCESS, props<any>());

const FETCH_SURVEY_VERSION_METADATA_FAILURE = '[create edite copy survey version page] fetch survey version metadata failure';
export const FetchSurveyVersionMetadataFailure = createAction(FETCH_SURVEY_VERSION_METADATA_FAILURE);

/******** CREATE SURVEY VERSION ***********/
const CREATE_SURVEY_VERSION_REQUEST = '[create edite copy survey version page] create survey version request';
export const CreateSurveyVersionRequest = createAction(CREATE_SURVEY_VERSION_REQUEST, props<any>());

const CREATE_SURVEY_VERSION_SUCCESS = '[create edite copy survey version page] create survey version success';
export const CreateSurveyVersionSuccess = createAction(CREATE_SURVEY_VERSION_SUCCESS, props<any>());

const CREATE_SURVEY_VERSION_FAILURE = '[create edite copy survey version page] create survey version failure';
export const CreateSurveyVersionFailure = createAction(CREATE_SURVEY_VERSION_FAILURE);

/******** UPDATE SURVEY VERSION ***********/
const UPDATE_SURVEY_VERSION_REQUEST = '[create edite copy survey version page] update survey version request';
export const UpdateSurveyVersionRequest = createAction(UPDATE_SURVEY_VERSION_REQUEST, props<any>());

const UPDATE_SURVEY_VERSION_SUCCESS = '[create edite copy survey version page] update survey version success';
export const UpdateSurveyVersionSuccess = createAction(UPDATE_SURVEY_VERSION_SUCCESS, props<any>());

const UPDATE_SURVEY_VERSION_FAILURE = '[create edite copy survey version page] update survey version failure';
export const UpdateSurveyVersionFailure = createAction(UPDATE_SURVEY_VERSION_FAILURE);

/******** FETCH MASTER SURVEY VERSION FILTER ***********/
const FETCH_MASTER_SURVEY_VERSION_FILTER_REQUEST = '[create edite copy survey version page] fetch master survey version filter request';
export const FetchMasterSurveyVersionFilterRequest = createAction(FETCH_MASTER_SURVEY_VERSION_FILTER_REQUEST, props<any>());

const FETCH_MASTER_SURVEY_VERSION_FILTER_SUCCESS = '[create edite copy survey version page] fetch master survey version filter success';
export const FetchMasterSurveyVersionFilterSuccess = createAction(FETCH_MASTER_SURVEY_VERSION_FILTER_SUCCESS, props<any>());

const FETCH_MASTER_SURVEY_VERSION_FILTER_FAILURE = '[create edite copy survey version page] fetch master survey version filter failure';
export const FetchMasterSurveyVersionFilterFailure = createAction(FETCH_MASTER_SURVEY_VERSION_FILTER_FAILURE);
