import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  UnauthenticatedWrapperComponent
} from "./modules/shared/components/wrappers/unauthenticated-wrapper/unauthenticated-wrapper.component";
import {
  AuthenticatedWrapperComponent
} from "./modules/shared/components/wrappers/authenticated-wrapper/authenticated-wrapper.component";
import {authenticatedGuard} from "./modules/shared/guards/authenticated.guard";
import {
  unauthenticatedGuard
} from "./modules/shared/guards/unauthenticated.guard";
import {
  ExternalPageNotFoundComponent
} from "./modules/shared/pages/page-not-found/external-page-not-found/external-page-not-found.component";
import {clientAdminGuard} from "./modules/shared/guards/client-admin.guard";
import {superAdminGuard} from "./modules/shared/guards/super-admin.guard";
import {clientUserGuard} from "./modules/shared/guards/client-user.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    canActivate: [unauthenticatedGuard],
    component: UnauthenticatedWrapperComponent,
    loadChildren: () =>
      import('./modules/page-modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'reset-password',
    canActivate: [unauthenticatedGuard],
    component: UnauthenticatedWrapperComponent,
    loadChildren: () =>
      import('./modules/page-modules/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'samples',
    component: UnauthenticatedWrapperComponent,
    loadChildren: () =>
      import('./modules/samples/samples.module').then((m) => m.SamplesModule),
  },
   {
    path: 'survey',
    component: UnauthenticatedWrapperComponent,
    loadChildren: () =>
      import('./modules/page-modules/survey/survey.module').then((m) => m.SurveyModule),
  },
  {
    path: 'super-admin',
    canActivate: [authenticatedGuard, superAdminGuard],
    component: AuthenticatedWrapperComponent,
    loadChildren: () =>
      import('./modules/role-modules/super-admin/super-admin.module').then((m) => m.SuperAdminModule),
  },
  {
    path: 'client-admin',
    canActivate: [authenticatedGuard, clientAdminGuard],
    component: AuthenticatedWrapperComponent,
    loadChildren: () =>
      import('./modules/role-modules/client-admin/client-admin.module').then((m) => m.ClientAdminModule),
  },
  {
    path: 'client-user',
    canActivate: [authenticatedGuard, clientUserGuard],
    component: AuthenticatedWrapperComponent,
    loadChildren: () =>
      import('./modules/role-modules/client-user/client-user.module').then((m) => m.ClientUserModule),
  },
  {
    path: '**',
    component: ExternalPageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
