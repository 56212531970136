<div class="page-wrapper w-full md:p-[10rem] sm:p-[6rem] p-[2rem]">
  <div class="heading text-[3rem]">
    The page that you are looking for does not exist.
  </div>
  <app-primary-button
    class="xs:w-full sm:w-3/4 md:w-1/3 lg:w-1/4"
    (handleClick)="redirectHome()"
  >
    Go to home
  </app-primary-button>
</div>
