/* NgRx */
import { createAction, props } from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[clients list page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/*********** FETCH COUNTY FILTER **************/
const FETCH_COUNTY_FILTER_REQUEST =
  '[clients list page] fetch county filter requested';
export const FetchCountyFilterRequest = createAction(
  FETCH_COUNTY_FILTER_REQUEST,
  props<any>()
);

const FETCH_COUNTY_FILTER_SUCCESS =
  '[clients list page] fetch county filter successful';
export const FetchCountyFilterSuccess = createAction(
  FETCH_COUNTY_FILTER_SUCCESS,
  props<any>()
);

const FETCH_COUNTY_FILTER_FAILURE =
  '[clients list page] fetch county filter failure';
export const FetchCountyFilterFailure = createAction(
  FETCH_COUNTY_FILTER_FAILURE,
  props<any>()
);

/*********** FETCH ORGANIZATION TYPE FILTER **************/
const FETCH_ORGANIZATION_TYPE_FILTER_REQUEST =
  '[clients list page] fetch organization type filter requested';
export const FetchOrganizationTypeFilterRequest = createAction(
  FETCH_ORGANIZATION_TYPE_FILTER_REQUEST,
  props<any>()
);

const FETCH_ORGANIZATION_TYPE_FILTER_SUCCESS =
  '[clients list page] fetch organization type filter successful';
export const FetchOrganizationTypeFilterSuccess = createAction(
  FETCH_ORGANIZATION_TYPE_FILTER_SUCCESS,
  props<any>()
);

const FETCH_ORGANIZATION_TYPE_FILTER_FAILURE =
  '[clients list page] fetch organization type filter failure';
export const FetchOrganizationTypeFilterFailure = createAction(
  FETCH_ORGANIZATION_TYPE_FILTER_FAILURE,
  props<any>()
);

/*********** FETCH CLIENT FILTER **************/
const FETCH_CLIENT_FILTER_REQUEST =
  '[clients list page] fetch client filter requested';
export const FetchClientFilterRequest = createAction(
  FETCH_CLIENT_FILTER_REQUEST,
  props<any>()
);

const FETCH_CLIENT_FILTER_SUCCESS =
  '[clients list page] fetch client filter successful';
export const FetchClientFilterSuccess = createAction(
  FETCH_CLIENT_FILTER_SUCCESS,
  props<any>()
);

const FETCH_CLIENT_FILTER_FAILURE =
  '[clients list page] fetch client filter failure';
export const FetchClientFilterFailure = createAction(
  FETCH_CLIENT_FILTER_FAILURE,
  props<any>()
);

/*********** FETCH CLIENT LIST **************/
const FETCH_CLIENT_LIST_REQUEST =
  '[clients list page] fetch client list requested';
export const FetchClientListRequest = createAction(
  FETCH_CLIENT_LIST_REQUEST,
  props<any>()
);

const FETCH_CLIENT_LIST_SUCCESS =
  '[clients list page] fetch client list successful';
export const FetchClientListSuccess = createAction(
  FETCH_CLIENT_LIST_SUCCESS,
  props<any>()
);

const FETCH_CLIENT_LIST_FAILURE =
  '[clients list page] fetch client list failure';
export const FetchClientListFailure = createAction(
  FETCH_CLIENT_LIST_FAILURE,
  props<any>()
);

/*********** FETCH CLIENT TYPE FILTER **************/
const FETCH_CLIENT_TYPE_FILTER_REQUEST =
  '[clients list page] fetch client type filter requested';
export const FetchClientTypeFilterRequest = createAction(
  FETCH_CLIENT_TYPE_FILTER_REQUEST,
  props<any>()
);

const FETCH_CLIENT_TYPE_FILTER_SUCCESS =
  '[clients list page] fetch client type filter successful';
export const FetchClientTypeFilterSuccess = createAction(
  FETCH_CLIENT_TYPE_FILTER_SUCCESS,
  props<any>()
);

const FETCH_CLIENT_TYPE_FILTER_FAILURE =
  '[clients list page] fetch client type filter failure';
export const FetchClientTypeFilterFailure = createAction(
  FETCH_CLIENT_TYPE_FILTER_FAILURE,
  props<any>()
);

/*********** FETCH SCHOOL DISTRICT FILTER **************/
const FETCH_SCHOOL_DISTRICT_FILTER_REQUEST =
  '[clients list page] fetch school district filter requested';
export const FetchSchoolDistrictFilterRequest = createAction(
  FETCH_SCHOOL_DISTRICT_FILTER_REQUEST,
  props<any>()
);

const FETCH_SCHOOL_DISTRICT_FILTER_SUCCESS =
  '[clients list page] fetch school district filter successful';
export const FetchSchoolDistrictFilterSuccess = createAction(
  FETCH_SCHOOL_DISTRICT_FILTER_SUCCESS,
  props<any>()
);

const FETCH_SCHOOL_DISTRICT_FILTER_FAILURE =
  '[clients list page] fetch school district filter failure';
export const FetchSchoolDistrictFilterFailure = createAction(
  FETCH_SCHOOL_DISTRICT_FILTER_FAILURE,
  props<any>()
);

/*********** FETCH STATE FILTER **************/
const FETCH_STATE_FILTER_REQUEST =
  '[clients list page] fetch state filter requested';
export const FetchStateFilterRequest = createAction(
  FETCH_STATE_FILTER_REQUEST,
  props<any>()
);

const FETCH_STATE_FILTER_SUCCESS =
  '[clients list page] fetch state filter successful';
export const FetchStateFilterSuccess = createAction(
  FETCH_STATE_FILTER_SUCCESS,
  props<any>()
);

const FETCH_STATE_FILTER_FAILURE =
  '[clients list page] fetch state filter failure';
export const FetchStateFilterFailure = createAction(
  FETCH_STATE_FILTER_FAILURE,
  props<any>()
);

/******** UPDATE CLIENT ***********/
const UPDATE_CLIENT_REQUEST = '[clients list page] update client request';
export const UpdateClientRequest = createAction(
  UPDATE_CLIENT_REQUEST,
  props<any>()
);

const UPDATE_CLIENT_SUCCESS = '[clients list page] update client success';
export const UpdateClientSuccess = createAction(
  UPDATE_CLIENT_SUCCESS,
  props<any>()
);

const UPDATE_CLIENT_FAILURE = '[clients list page] update client failure';
export const UpdateClientFailure = createAction(
  UPDATE_CLIENT_FAILURE,
  props<any>()
);
