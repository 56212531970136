import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  FetchMenuRequest,
  FetchMenuSuccess,
  FetchMenuFailure
} from './super-admin.actions';
import {SuperAdminService} from './super-admin.service';
import * as fromRoot from '../../../../state/app.state';
import {CommonService} from "../../../shared/services/common.service";
import {ActionModel} from "../../../../models/action.model";


@Injectable()
export class SuperAdminEffects {
  login$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchMenuRequest),
      map((action: ActionModel) => {
        return action.payload;
      }),
      mergeMap((payload: any) =>
        this.superAdminService.fetchMenu(payload).pipe(
          map((response: any) => {
            const {data} = response;

            return FetchMenuSuccess({menu: data});
          }),
          catchError(() => {
            return of(FetchMenuFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchMenuSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchMenuFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  constructor(
    private store: Store<fromRoot.State>,
    private superAdminService: SuperAdminService,
    private actions: Actions,
    private router: Router,
    private commonService: CommonService
  ) {
  }
}
