import {Component, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {profile} from "../../core/shared.selectors";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../../../state/app.state";
import {roles} from "../../constants/common.constants";
import {CommonService} from "../../services/common.service";

@Component({
  selector: 'app-menu-page',
  templateUrl: './menu-page.component.html',
  styleUrls: ['./menu-page.component.css']
})
export class MenuPageComponent implements OnInit {
  administrationMenu: any[] = [];
  libraryMenu: any[] = [];
  role: any;
  clientId: any;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private router: Router,
    private store: Store<fromRoot.State>,
  ) {
    this.route.data
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.administrationMenu = data['administrationMenu'];
        this.libraryMenu = data['libraryMenu'];
      });

    this.store.select(profile)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((d) => {
        this.role = d?.role;
        this.clientId = d?.client_id;
      });
  }

  ngOnInit() {
    if (this.role === roles.clientAdmin) {
      const index = this.administrationMenu.findIndex(o => o.name === 'Our Details')

      if (index === -1) {
        this.administrationMenu.push({
          image: '/assets/images/webp/menu-clients.webp',
          path: `/m/client-details/${this.clientId}`,
          name: 'Our Details'
        })
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
