<div
  class="confirmation-dialog flex flex-col h-full justify-center items-center space-y-[5rem]"
>
  <div class="message text-center">
    <span [innerHtml]="message"></span>
  </div>
  <div
    class="action-panel w-full flex xs:flex-col sm:flex-row justify-center items-center sm:space-x-[5rem] space-y-[5rem] sm:space-y-0"
  >
    <app-secondary-button
      (handleClick)="handleSubmit()"
      class="submit sm:w-[20rem] w-full"
      type="small"
    >
      {{ submitText }}
    </app-secondary-button>
    <app-primary-button
      (handleClick)="handleCancel()"
      class="cancel sm:w-[20rem] w-full"
      type="small"
    >
      {{ cancelText }}
    </app-primary-button>
  </div>
</div>
