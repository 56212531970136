import { Component } from '@angular/core';
import {CommonService} from "../../services/common.service";
import {Router} from "@angular/router";


declare var google: any;
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-google-translate',
  template: `<div id="google_translate_element"></div>`,
  styleUrls: ['./google-translate.component.css']
})
export class GoogleTranslateComponent {

  url = '';

  constructor(
    private commonService: CommonService,
    private router: Router

  ) {
    this.url = this.router.url;
  }

  ngAfterViewInit() {
    let waitingForGoogleLoad = setInterval(function () {
      if (typeof (google) != 'undefined' && typeof (google.translate.TranslateElement) != 'undefined' && typeof (google.translate.TranslateElement.InlineLayout) != 'undefined') {
        clearInterval(waitingForGoogleLoad);

        new google.translate.TranslateElement({
          pageLanguage: 'en, es',
          includedLanguages: 'ar,en,zh-CN,es,tl,ko,ja,'
        }, 'google_translate_element');

        $("#google_translate_element").html(google.translate.TranslateElement().ia)

        const select = $('#google_translate_element .goog-te-gadget > div ');
        $('#google_translate_element .goog-te-gadget').empty()
        $('#google_translate_element .goog-te-gadget').html(select);

        if (!$('#google_translate_element .goog-te-gadget').length) {
          if (!this.url?.includes('login') && !this.url?.includes('password')) {
            window.location.reload();
          }
        }
      }
    }, 1000);
  }
}
