import {Component, Inject, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CommonService} from "../../../services/common.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.css']
})
export class DialogBoxComponent implements OnInit {
  @ViewChild('anchor', {static: true, read: ViewContainerRef}) anchor;
  dialogHeading: any;
  showActionSection: boolean = false;
  url: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogBoxComponent>,
    private commonService: CommonService,
    private router: Router
  ) {
    this.url = this.router.url;
  }

  ngOnInit(): void {
    this.anchor.clear();
    const {component, dialogHeading, showActionSection} = this.data;
    this.dialogHeading = dialogHeading;
    showActionSection ? this.showActionSection = showActionSection : this.showActionSection = false;
    delete this.data?.component;
    const componentRef = this.anchor.createComponent(component);
    componentRef.instance.data = this.data;
  }

  accept() {
    this.commonService.tosAccepted.next(true);
  }

  closeDialog() {
    if (this.url.includes('login')) {
      this.commonService.logout();
    }
    this.dialogRef.close();
  }
}
