/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[survey page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** FETCH_SURVEY ***********/
const FETCH_SURVEY_REQUEST = '[survey page] fetch survey requested';
export const FetchSurveyRequest = createAction(FETCH_SURVEY_REQUEST, props<{ payload: any }>());

const FETCH_SURVEY_SUCCESS = '[survey page] fetch survey successful';
export const FetchSurveySuccess = createAction(FETCH_SURVEY_SUCCESS, props<any>());

const FETCH_SURVEY_FAILURE = '[survey page] fetch survey failure';
export const FetchSurveyFailure = createAction(FETCH_SURVEY_FAILURE);

/******** SAVE_PARTIAL_SURVEY ***********/
const SAVE_PARTIAL_SURVEY_REQUEST = '[survey page] save partial survey requested';
export const SavePartialSurveyRequest = createAction(SAVE_PARTIAL_SURVEY_REQUEST, props<{ payload: any }>());

const SAVE_PARTIAL_SURVEY_SUCCESS = '[survey page] save partial survey successful';
export const SavePartialSurveySuccess = createAction(SAVE_PARTIAL_SURVEY_SUCCESS, props<{ payload: any}>());

const SAVE_PARTIAL_SURVEY_FAILURE = '[survey page] save partial survey failure';
export const SavePartialSurveyFailure = createAction(SAVE_PARTIAL_SURVEY_FAILURE, props<any>());

/******** SAVE_COMPLETE_SURVEY ***********/
const SAVE_COMPLETE_SURVEY_REQUEST = '[survey page] save complete survey requested';
export const SaveCompleteSurveyRequest = createAction(SAVE_COMPLETE_SURVEY_REQUEST, props<any>());

const SAVE_COMPLETE_SURVEY_SUCCESS = '[survey page] save complete survey successful';
export const SaveCompleteSurveySuccess = createAction(SAVE_COMPLETE_SURVEY_SUCCESS, props<any>());

const SAVE_COMPLETE_SURVEY_FAILURE = '[survey page] save complete survey failure';
export const SaveCompleteSurveyFailure = createAction(SAVE_COMPLETE_SURVEY_FAILURE);

/******** FETCH_SURVEY_ANSWERS ***********/
const FETCH_SURVEY_ANSWERS_REQUEST = '[survey page] fetch survey answers requested';
export const FetchSurveyAnswersRequest = createAction(FETCH_SURVEY_ANSWERS_REQUEST, props<{ payload: any }>());

const FETCH_SURVEY_ANSWERS_SUCCESS = '[survey page] fetch survey answers successful';
export const FetchSurveyAnswersSuccess = createAction(FETCH_SURVEY_ANSWERS_SUCCESS, props<any>());

const FETCH_SURVEY_ANSWERS_FAILURE = '[survey page] fetch survey answers failure';
export const FetchSurveyAnswersFailure = createAction(FETCH_SURVEY_ANSWERS_FAILURE);
