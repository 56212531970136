import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../state/app.state';
import {BehaviorSubject, catchError, map, Subject, throwError} from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DOCUMENT} from '@angular/common';
import {AbstractControl, FormGroup, ValidationErrors} from '@angular/forms';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {DialogBoxComponent} from '../components/dialogbox/basic-dialog/dialog-box.component';
import {ConfirmationDialogComponent} from '../components/dialogbox/confirmation-dialog/confirmation-dialog.component';
import * as SharedActions from '../core/shared.actions';
import {ClearState as ClearSharedState} from '../core/shared.actions';
import {ClearState as ClearLoginState} from '../../page-modules/login/core/login.actions';
import {ClearState as ClearSuperAdminState} from '../../role-modules/super-admin/core/super-admin.actions';
import {ClearState as ClearClientAdminState} from '../../role-modules/client-admin/core/client-admin.actions';
import {ClearState as ClearClientUserState} from '../../role-modules/client-user/core/client-user.actions';
import {ClearState} from '../../page-modules/reports/core/reports.actions';
import {ClearState as ClearUserRolesState} from '../../page-modules/user-roles/core/user-roles.actions'
import {
  ClearState as ClearCreateEditCopySurveyState
} from '../../page-modules/create-edit-copy-survey/core/create-edit-copy-survey.actions'
import {ClearState as ClearManageUsersState} from '../../page-modules/manage-users/core/manage-users.actions';
import {
  ClearState as ClearManageSchoolsOfClientState
} from '../../page-modules/manage-schools-of-clients/core/manage-schools-of-client.actions'
import {
  ClearState as ClearManageSchoolsOfUserState
} from '../../page-modules/manage-schools-of-users/core/manage-schools-of-user.actions'
import {ClearState as ClearDownloadsState} from '../../page-modules/downloads/core/downloads.actions';
import {ClearState as ClearClientsListState} from '../../page-modules/clients-list/core/clients-list.actions';
import {ClearState as ClearClientDetailsState} from '../../page-modules/client-details/core/client-details.actions';
import {ClearState as ClearSchoolsListState} from '../../page-modules/schools-list/core/schools-list.actions';
import {ClearState as ClearSchoolDetailsState} from '../../page-modules/school-details/core/school-details.actions';
import {ClearState as ClearUsersListState} from '../../page-modules/users-list/core/users-list.actions';
import {ClearState as ClearUserDetailsState} from '../../page-modules/user-details/core/user-details.actions'
import {ClearState as ClearSurveyVersionsState} from '../../page-modules/survey-versions/core/survey-versions.actions';
import {ClearState as ClearSurveyState} from '../../page-modules/survey/core/survey.actions'


import {profile} from '../core/shared.selectors';
import {roles} from '../constants/common.constants';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  openMobileDialog: Subject<any> = new Subject();
  closeMobileDialog: Subject<void> = new Subject();
  onNavigationEnd: BehaviorSubject<any> = new BehaviorSubject({});
  isLoadingScreenOpen: Subject<boolean> = new Subject();
  tosAccepted: Subject<boolean> = new Subject();
  setSidenavButtonVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  triggerReport: Subject<any> = new Subject();
  alphabetsRegex = `^[^-\\s]([a-zA-Z])+$`;
  alphanumericRegex = `^[^-\\s]([0-9a-zA-Z])+$`;
  alphanumericAndSymbolsRegex = `^[^\\s]+([a-zA-Z0-9\\s,/_-]+[^\\s]+)*$`;
  applyPanelHeight: Subject<any> = new Subject();
  panelHeight: any;
  // Used for copyToClipboard(value)
  private dom: Document;

  constructor(
    @Inject(DOCUMENT) dom?: Document,
    private store?: Store<fromRoot.State>,
    private router?: Router,
    private http?: HttpClient,
    private dialog?: MatDialog,
    private snackBar?: MatSnackBar
  ) {
    this.dom = dom;
  }

  setToLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  removeFromLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  getFromLocalStorage(key: string) {
    return localStorage.getItem(key);
  }

  setToSessionStorage(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  getFromSessionStorage(key: string) {
    return sessionStorage.getItem(key);
  }

  removeFromSessionStorage(key: string) {
    sessionStorage.removeItem(key);
  }

  setAuthenticationToken(token: string) {
    this.setToLocalStorage('school-voices/access-token', token);
  }

  getAuthenticationToken() {
    return this.getFromLocalStorage('school-voices/access-token');
  }

  removeAuthenticationToken() {
    return this.removeFromLocalStorage('school-voices/access-token');
  }

  setRefreshToken(token: string) {
    this.setToLocalStorage('school-voices/refresh-token', token);
  }

  getRefreshToken() {
    return this.getFromLocalStorage('school-voices/refresh-token');
  }

  removeRefreshToken() {
    return this.removeFromLocalStorage('school-voices/refresh-token');
  }

  isAuthenticated() {
    return !!this.getAuthenticationToken();
  }

  setAcceptedTosToken(data: any) {
    this.setToLocalStorage('school-voices/accepted-tos-status', data);
  }

  getAcceptedTosToken() {
    return this.getFromLocalStorage('school-voices/accepted-tos-status');
  }

  callAPI(type: string, url: string, payload?: any, options: any = {}) {
    let apiCall = this.http[type]<any>(url, payload, {
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      ...options
    });

    if (type === 'delete') {
      apiCall = this.http[type]<any>(url, {
        observe: 'response',
        body: payload || {},
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        ...options
      });
    }

    return apiCall.pipe(
      map((response: any) => {
        if (response instanceof HttpResponse) {
          return response.body;
        }

        return response;
      }),
      catchError((error) => throwError(error))
    );
  }

  startLoading() {
    this.store.dispatch(SharedActions.StartLoading());
  }

  stopLoading() {
    this.store.dispatch(SharedActions.StopLoading());
  }

  openDesktopDialog(config: any = {}) {
    config = {
      ...config
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = [
      ...(config.panelWidth ? [`panel-width-${config.panelWidth}`] : []),
      ...(config.panelHeight ? [`panel-height-${config.panelHeight}`] : [])
    ];
    return this.dialog.open(DialogBoxComponent, {
      ...dialogConfig,
      ...config,
      autoFocus: false,
      ...(config.data.disableClose ? {disableClose: true} : {disableClose: false})
    })
  }

  closeDesktopDialog() {
    this.dialog.closeAll();
  }

  checkFormValidation(form: FormGroup, errorMessageMap: any,
                      currentField?: string
  ) {
    const errorMessages = {};
    const formControls = form.controls || null;

    if (currentField) {
      const errors = form.controls[currentField].errors;
      const firstErrorType = errors ? Object.keys(errors)[0] : '';
      // @ts-ignore
      errorMessages[currentField] = errorMessageMap[currentField][firstErrorType];
      return errorMessages;
    }

    for (const eachControlName in formControls) {
      const isControlValid = form.controls[eachControlName].valid;
      if (!isControlValid) {
        const errors = form.controls[eachControlName].errors;
        const firstErrorType = errors ? Object.keys(errors)[0] : null;
        if (firstErrorType) {
          form.controls[eachControlName].markAsTouched();
          errorMessages[eachControlName] = errorMessageMap[eachControlName][firstErrorType];
        }
      }
    }
    return errorMessages;
  }

  notification(
    message: string,
    type = '',
    actionText?: string,
    duration = 1500,
    verticalPosition: MatSnackBarVerticalPosition = 'top',
    horizontalPosition: MatSnackBarHorizontalPosition = 'center'
  ) {
    if (type === 'danger') {
      actionText = 'X'
      duration = 5000
    }

    const snackBarRef = this.snackBar.open(message, actionText, {
      duration,
      verticalPosition,
      horizontalPosition,
      panelClass: [type]
    });

    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
    });
  }

  closeNotification() {
    this.snackBar.dismiss();
  }

  openInNewTab(url: string) {
    window.open(url, '_blank');
  }

  openInCurrentTab(url: string) {
    window.open(url, '_self');
  }

  clearAppState() {
    this.store.dispatch(ClearSharedState());
    this.store.dispatch(ClearLoginState());
    this.store.dispatch(ClearSuperAdminState());
    this.store.dispatch(ClearClientAdminState());
    this.store.dispatch(ClearClientUserState());
    this.store.dispatch(ClearState({saveLoadingState: true}));
    this.store.dispatch(ClearSurveyVersionsState());
    this.store.dispatch(ClearSurveyState());
    this.store.dispatch(ClearUserRolesState());
    this.store.dispatch(ClearCreateEditCopySurveyState());
    this.store.dispatch(ClearManageUsersState());
    this.store.dispatch(ClearManageSchoolsOfClientState());
    this.store.dispatch(ClearManageSchoolsOfUserState());
    this.store.dispatch(ClearDownloadsState());
    this.store.dispatch(ClearClientsListState());
    this.store.dispatch(ClearClientDetailsState());
    this.store.dispatch(ClearSchoolsListState());
    this.store.dispatch(ClearSchoolDetailsState());
    this.store.dispatch(ClearUsersListState());
    this.store.dispatch(ClearUserDetailsState());

    this.closeDialog();
  }

  clearStorage() {
    this.removeAuthenticationToken();
    this.removeRefreshToken();

    const appLocalStorageKeys = [
      'school-voices.shared',
      'school-voices.super-admin',
      'school-voices.client-admin',
      'school-voices.client-user',
      'school-voices.login',
      'school-voices.reports',
      'school-voices.survey-versions',
      'school-voices.survey',
      'school-voices.manage-schools-of-client',
      'school-voices.manage-schools-of-user'
    ];

    appLocalStorageKeys.forEach((key) => {
      this.removeFromSessionStorage(key);
    });
  }

  logout() {
    this.clearStorage();
    this.clearAppState();
    console.log('logout');
    this.router.navigate(['/login']);
  }

  openDialog(params: any) {
    return this.openDesktopDialog(params);
  }

  closeDialog() {
    // Close mobile dialog.
    this.closeMobileDialog.next();

    // Close desktop dialog
    this.closeDesktopDialog();
  }

  validatePassword(control: AbstractControl): ValidationErrors | null {
    const password = control.value;
    let errors = {};

    const containsLowercase = new RegExp('[a-z]');
    if (!containsLowercase.test(password)) {
      errors = {
        ...errors,
        lowerCaseCheckFailed: true
      }
      return errors;
    }

    const containsUppercase = new RegExp('[A-Z]');
    if (!containsUppercase.test(password)) {
      errors = {
        ...errors,
        upperCaseCheckFailed: true
      }
      return errors;
    }

    const containsNumber = new RegExp('[0-9]');
    if (!containsNumber.test(password)) {
      errors = {
        ...errors,
        numberCheckFailed: true
      }
      return errors;
    }

    const containsSpecialChar = new RegExp('[!@#$%^&*()\\\\/\\[\\]\-_=+{}|?>.<,:;~`\'÷×₹€¥"]');
    if (!containsSpecialChar.test(password)) {
      errors = {
        ...errors,
        specialCharCheckFailed: true
      }
      return errors;
    }

    return null;
  }

  setFocus(form: FormGroup = new FormGroup({}), errorMessages: any = {},
           focus: any = {}
  ) {
    const formFieldsList = Object.keys(form.controls);

    for (const eachFormField of formFieldsList) {
      if (errorMessages[eachFormField] !== undefined) {
        focus[eachFormField] = true;
        return;
      } else {
        focus[eachFormField] = false;
      }
    }
  }

  headerRemOffsetCalculator() {
    const currentScreenWidth = window.innerWidth;
    let remBase = 20;

    if (currentScreenWidth < 1439) {
      remBase = 8;
    } else if (currentScreenWidth < 1919) {
      remBase = 10;
    } else if (currentScreenWidth < 2559) {
      remBase = 12;
    } else if (currentScreenWidth < 3439) {
      remBase = 16;
    }

    return 14 * remBase;
  }

  openConfirmationDialog(config = {}) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'confirmation-dialog';
    return this.dialog.open(ConfirmationDialogComponent, {
      ...dialogConfig,
      disableClose: true, ...config
    });
  }

  getBaseRoute() {
    let baseRoute = '';
    this.store.select(profile).subscribe(data => {

      switch (data?.role) {
        case roles.superAdmin:
          baseRoute = '/super-admin'
          break;
        case roles.clientAdmin:
        case roles.schoolAdmin:
          baseRoute = '/client-admin'
          break;
        case roles.clientUser:
        case roles.schoolUser:
          baseRoute = '/client-user'
          break;
        default:
          baseRoute = '/'
          break;
      }
    }).unsubscribe();
    return baseRoute;
  }

  loadScript(url) {
    let scripts = document.getElementsByTagName('script');

    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].getAttribute('src') === url) {
        scripts[i].parentNode.removeChild(scripts[i]);
        break;
      }
    }

    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  centerReport(reportElement) {
    const report = $(reportElement);
    const parentWidth = report.parent().width();
    const reportWidth = reportElement.getBoundingClientRect().width;

    let marginLeft = 0;

    if (parentWidth > reportWidth) {
      marginLeft = (parentWidth / 2) - (reportWidth / 2);
    }

    report.css('margin-left', `${marginLeft}px`);
  }
}
