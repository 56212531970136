import {Component} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {FlatTreeControl} from "@angular/cdk/tree";
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonService} from "../../services/common.service";
import {profile} from "../../core/shared.selectors";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../../../state/app.state";
import {roles} from "../../constants/common.constants";

@Component({
  selector: 'app-menu-sidenav',
  templateUrl: './menu-sidenav.component.html',
  styleUrls: ['./menu-sidenav.component.css']
})
export class MenuSidenavComponent {
  private readonly onDestroy: Subject<any> = new Subject<any>();
  role: any;
  clientId: any;
  menu: any[];

  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      label: node.label,
      level: level,
      link: node.link
    };
  };

  treeControl = new FlatTreeControl<any>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    private router: Router,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
  ) {
    this.store.select(profile)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data: any) => {
        this.role = data?.role;
        this.clientId = data?.client_id;
      });

    this.route.data
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data: any) => {
        if (this.role === roles.clientAdmin) {
          this.dataSource.data = [
            {
              key: 'clientDetails',
              label: 'Our Details',
              link: `/client-details/${this.clientId}`
            },
            ...data?.config,
          ] || [];
        } else {
          this.dataSource.data = data.config || [];
        }
      });
  }

  navigate(link) {
    this.router.navigate([`${this.commonService.getBaseRoute()}/m${link}`]);
  }

  hasChild = (_: number, node: any) => node.expandable;

  isSelected(link) {
    return this.router.url === `${this.commonService.getBaseRoute()}/m${link}`;
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
