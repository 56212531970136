import {environment} from '../../../../environments/environment';

const baseURL = environment.apiURL;

const v1Base = `${baseURL}/api`;

const prepareUrlParams = (url: string, params: any, skipParams = []) => {
  const urlInstance = new URL(url);
  let hasParams = false;
  let additionParams = ``;
  if (params) {
    const multiParams = [];
    for (const paramsKey in params) {
      if (
        params[paramsKey] !== undefined &&
        params[paramsKey] !== null &&
        !skipParams.includes(paramsKey)
      ) {
        if (Array.isArray(params[paramsKey])) {
          // handle array params
          params[paramsKey].forEach((item) => {
            multiParams.push(`${paramsKey}=${encodeURIComponent(item)}`);
          });
        } else if (('' + params[paramsKey]).length) {
          // handle single params
          hasParams = true;
          urlInstance.searchParams.append(paramsKey, params[paramsKey]);
        }
      }
    }
    if (multiParams.length) {
      additionParams = multiParams.join('&');
    }
  }
  let urlString = urlInstance.toString();
  if (additionParams) {
    urlString += hasParams ? `&${additionParams}` : `?${additionParams}`;
  }
  return urlString;
};

export const v1URL = {
  login: {
    method: 'post',
    url: `${baseURL}/oauth/token`,
  },
  clients: {
    svClients: {
      fetch: {
        method: 'get',
        url: () => `${v1Base}/client/sv-clients`,
      },
    },
  },
  taxonomy: {
    category: {
      fetch: {
        method: 'get',
        url: (params) =>
          `${v1Base}/taxonomy/category?type_id=${params.typeId}&client_id=${params.clientId}`,
      },
    },
    subcategory: {
      fetch: {
        method: 'get',
        url: (params) =>
          `${v1Base}/taxonomy/subcategory?category_id=${params.categoryId}&client_id=${params.clientId}`,
      },
    },
    item: {
      fetch: {
        method: 'get',
        url: (params) => {
          let urlObj = new URL(`${v1Base}/taxonomy/item`);

          if (params.subcategoryId !== undefined) {
            urlObj.searchParams.append('subcategory_id', params.subcategoryId);
          }
          if (params.clientId !== undefined) {
            urlObj.searchParams.append('client_id', params.clientId);
          }

          return urlObj.toString();
        }
      },
      fetchById: {
        method: 'get',
        url: (params) =>
          `${v1Base}/taxonomy/item/${params.itemId}?subcategory_id=${params.subcategoryId}&client_id=${params.clientId}`,
      },
      download: {
        method: 'get',
        url: (params) => prepareUrlParams(`${v1Base}/taxonomy/item/download`, params)
      }
    },
    menu: {
      method: 'get',
      url: (params) =>
        prepareUrlParams(`${v1Base}/taxonomy/GetTaxonomyMenu`, params),
    },
    getReportFilterMasterSurveyV3: {
      method: 'get',
      url: (params) =>
        `${v1Base}/taxonomy/GetReportFilterMasterSurveyV3?client_id=${params.clientId}`,
    },
  },
  survey: {
    versions: {
      fetch: {
        method: 'get',
        url: (params) =>
          `${v1Base}/survey/GetClientSurveyVersions?clientId=${params.clientId}`,
      },
    },
    updateSurveyAcceptingResponses: {
      method: 'get',
      url: (params) =>
        `${v1Base}/survey/UpdateAcceptingResponses?surveyVersionGuid=${params.guid}&responseFlag=${params.active}`,
    },
    fetch: {
      method: 'get',
      url: (params) => {
        let url = `${v1Base}/survey?id=${params.surveyId}`;
        if (params.languageId) {
          url += `&languageId=${params.languageId}`;
        }
        return url;
      },
    },
    exportSurveyData: {
      method: 'get',
      url: (params) =>
        `${v1Base}/survey/export-surveydata?id=${params.surveyId}&type=${params.typeId}`,
    },
    save: {
      partial: {
        method: 'post',
        url: () => `${v1Base}/survey/UpdateSurveyDeliveryResponder`,
      },
      complete: {
        method: 'post',
        url: () => `${v1Base}/survey`,
      },
    },
    answers: {
      method: 'get',
      url: (params: any) =>
        `${v1Base}/survey/GetSurveyDeliveryResponseByUID?deliveryResponderByUID=${params.uuid}`,
    },
    getAllMeasuresResponses: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(`${v1Base}/survey/GetAllMeasuresResponses`);
        if (params.surveyVersionId !== undefined) {
          urlObj.searchParams.append(
            'clientSurveyVersionId',
            params.surveyVersionId
          );
        }

        if (params.masterSurveyId !== undefined) {
          urlObj.searchParams.append('masterSurveyId', params.masterSurveyId);
        }

        if (params.clientId !== undefined) {
          urlObj.searchParams.append('clientId', params.clientId);
        }

        return urlObj.toString();
      },
    },
    getMeasuresOfMasterSurveyVersion: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(`${v1Base}/survey/GetMeasuresOfMasterSurveyVersion`);
        if (params.surveyVersionId !== undefined) {
          urlObj.searchParams.append(
            'clientSurveyVersionId',
            params.surveyVersionId
          );
        }

        if (params.masterSurveyVersionId !== undefined) {
          urlObj.searchParams.append('master_survey_version_id', params.masterSurveyVersionId);
        }

        if (params.clientId !== undefined) {
          urlObj.searchParams.append('clientId', params.clientId);
        }

        return urlObj.toString();
      },
    },
    getAllGradesResponses: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(`${v1Base}/survey/GetAllGradesResponses`);

        if (params.clientSurveyVersionId !== undefined) {
          urlObj.searchParams.append(
            'clientSurveyVersionId',
            params.clientSurveyVersionId
          );
        }
        return urlObj.toString();
      },
    },
    clientSurveyVersionMetadata: {
      method: 'get',
      url: (params) =>
        `${v1Base}/survey/ReturnClientSurveyVersionMetadata?clientSurveyVersionId=${params.surveyVersionId}`,
    },
    createClientSurveyVersion: {
      method: 'post',
      url: () => `${v1Base}/survey/CreateClientSurveyVersion`,
    },
    updateClientSurveyVersion: {
      method: 'put',
      url: () => `${v1Base}/survey/UpdateClientSurveyVersion`,
    },
    copyClientSurveyVersion: {
      method: 'post',
      url: () => `${v1Base}/survey/CopyClientSurveyVersion`,
    },
    getListFilterOrganizationalUnits: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(
          `${v1Base}/survey/GetListfilterOrganizationalUnits`
        );
        urlObj.searchParams.append('client_id', params.clientId);
        return urlObj.toString();
      },
    },
    getListFilterSchoolYears: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(`${v1Base}/survey/GetListfilterSchoolYears`);
        urlObj.searchParams.append('client_id', params.clientId);
        return urlObj.toString();
      },
    },
    getListFilterTimePeriod: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(`${v1Base}/survey/GetListfilterSurveyTimePeriod`);
        urlObj.searchParams.append('client_id', params.clientId);
        return urlObj.toString();
      },
    },
    getCreateFilterOrganizationalUnits: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(
          `${v1Base}/survey/GetCreatefilterOrganizationalUnits`
        );
        urlObj.searchParams.append('client_id', params.clientId);
        return urlObj.toString();
      },
    },
    getCreateFilterSchoolYears: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(
          `${v1Base}/survey/GetCreatefilterSurveyVersionSchoolYear`
        );
        urlObj.searchParams.append('client_id', params.clientId);
        return urlObj.toString();
      },
    },
    getCreateFilterTimePeriod: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(`${v1Base}/survey/GetCreateFilterTimePeriods`);
        urlObj.searchParams.append('client_id', params.clientId);
        return urlObj.toString();
      },
    },
    getCreateFilterStakeholderGroup: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(
          `${v1Base}/survey/GetCreateFilterStakeholderGroup`
        );

        if (params.clientId !== undefined) {
          urlObj.searchParams.append('client_id', params.clientId);
        }
        return urlObj.toString();
      },
    },
    getSurveyDeliveryResponders: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(`${v1Base}/survey/ReturnSurveyDeliveryResponders`);

        if (params.clientSurveyVersionId !== undefined) {
          urlObj.searchParams.append(
            'clientSurveyVersionId',
            params.clientSurveyVersionId
          );
        }
        return urlObj.toString();
      },
    },
    getReportFilterOrganizationalUnits: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(
          `${v1Base}/survey/GetReportFilterOrganizationalUnits`
        );

        if (params.masterSurveyVersionId !== undefined) {
          urlObj.searchParams.append('master_survey_id', params.masterSurveyVersionId);
        }

        if (params.taxonomy_item_id !== undefined) {
          urlObj.searchParams.append('taxonomy_item_id', params.taxonomy_item_id);
        }

        if (params.clientId !== undefined) {
          urlObj.searchParams.append('client_id', params.clientId);
        }

        return urlObj.toString();
      },
    },
    getReportFilterSchoolYears: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(`${v1Base}/survey/GetReportfilterSchoolYears`);

        if (params.masterSurveyVersionId !== undefined) {
          urlObj.searchParams.append('master_survey_id', params.masterSurveyVersionId);
        }

        if (params.clientId !== undefined) {
          if (Array.isArray(params.clientId)) {
            let searchParams = new URLSearchParams(urlObj.search);

            params.clientId?.forEach((item) => {
              searchParams.append('organizational_id', item);
            });

            urlObj.search = searchParams.toString();
          } else {
            urlObj.searchParams.append('organizational_id', params.clientId);
          }
        }

        return urlObj.toString();
      },
    },
    getReportFilterSurveyTimePeriod: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(
          `${v1Base}/survey/GetReportfilterSurveyTimePeriod`
        );

        if (params.masterSurveyVersionId !== undefined) {
          urlObj.searchParams.append('master_survey_id', params.masterSurveyVersionId);
        }

        if (params.clientId !== undefined) {
          urlObj.searchParams.append('client_id', params.clientId);
        }

        if (params.schoolId !== undefined) {
          if (Array.isArray(params.schoolId)) {
            let searchParams = new URLSearchParams(urlObj.search);

            params.schoolId?.forEach((item) => {
              searchParams.append('school_id', item);
            });

            urlObj.search = searchParams.toString();
          } else {
            urlObj.searchParams.append('school_id', params.schoolId);
          }
        }

        if (params.schoolYearId !== undefined) {
          urlObj.searchParams.append('school_year_id', params.schoolYearId);
        }

        return urlObj.toString();
      },
    },
    getReportFilterStakeholdergroup: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(
          `${v1Base}/survey/GetReportfilterStakeholderGroup`
        );

        if (params.masterSurveyVersionId !== undefined) {
          urlObj.searchParams.append('master_survey_id', params.masterSurveyVersionId);
        }

        if (params.clientId !== undefined) {
          urlObj.searchParams.append('client_id', params.clientId);
        }
        console.log(params);
        if (params.schoolId !== undefined) {
          if (Array.isArray(params.schoolId)) {
            let searchParams = new URLSearchParams(urlObj.search);

            params.schoolId?.forEach((item) => {
              searchParams.append('school_id', item);
            });

            urlObj.search = searchParams.toString();
          } else {
            urlObj.searchParams.append('school_id', params.schoolId);
          }
        }

        if (params.schoolYearId !== undefined) {
          urlObj.searchParams.append('school_year_id', params.schoolYearId);
        }

        if (params.timePeriod) {
          urlObj.searchParams.append('time_period', params.timePeriod);
        }

        return urlObj.toString();
      },
    },
    sendEmail: {
      method: 'post',
      url: `${v1Base}/survey/SendSurveyEmails`,
    },
    fetchMasterSurveyVersionFilters: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(
          `${v1Base}/survey/masterSurveyVersion`,
        );

        if (params.clientId !== undefined) {
          urlObj.searchParams.append('client_id', params.clientId);
        }

        return urlObj.toString();
      }
    },
  },
  role: {
    url: `${v1Base}/role`,
    method: 'get',
  },
  pricingTiers: {
    url: `${v1Base}/admin/pricingtiers`,
    method: 'get',
  },
  addRole: {
    url: `${v1Base}/role`,
    method: 'post',
  },
  editRole: {
    url: `${v1Base}/role/update`,
    method: 'post',
  },
  deleteRole: {
    url: (params) => `${v1Base}/role/delete?roleId=${params.roleId}`,
    method: 'post',
  },
  user: {
    fetch: {
      method: 'get',
      url: (params) => `${v1Base}/user?client_id=${params}`,
    },
    createNewAdmin: {
      method: 'post',
      url: `${v1Base}/user/createadmin`,
    },
    createNewUser: {
      method: 'post',
      url: `${v1Base}/user`,
    },
    delete: {
      method: 'post',
      url: (params) => {
        let urlObj = new URL(`${v1Base}/user/delete`);
        if (params.userId !== undefined) {
          urlObj.searchParams.append('userId', params.userId);
        }

        return urlObj.toString();
      },
    },
    update: {
      method: 'post',
      url: `${v1Base}/user/update`,
    },
    password: {
      reset: {
        method: 'post',
        url: (params) =>
          `${v1Base}/user/generatepasswordtoken?emailAddress=${params.username}`,
      },
      set: {
        method: 'post',
        url: `${v1Base}/user/resetpassword`,
      },
    },
    fetchById: {
      method: 'get',
      url: (params) => `${v1Base}/user/${params.userId}`,
    },
    fetchSelfDetails: {
      method: 'get',
      url: (params) => `${v1Base}/user/me`,
    },
    addNewUser: {
      method: 'post',
      url: `${v1Base}/user`,
    },
    acceptTos: {
      method: 'put',
      url: `${v1Base}/user/accept-tos`,
    },
  },
  schoolYears: {
    fetch: {
      url: (params?) =>
        `${v1Base}/schoolyear?isForReport=${params?.forReport || false}`,
      method: 'get',
    },
    add: {
      url: () => `${v1Base}/schoolyear`,
      method: 'post',
    },
  },
  school: {
    fetch: {
      method: 'get',
      url: (params) => `${v1Base}/school?client_id=${params}`,
    },
    add: {
      method: 'post',
      url: `${v1Base}/school`,
    },
    update: {
      method: 'post',
      url: `${v1Base}/school/updateschool`,
    },
    delete: {
      method: 'post',
      url: (params) => `${v1Base}/school/deleteschool?id=${params}`,
    },
  },
  schoolOfUser: {
    fetch: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(`${v1Base}/schoolofuser`);

        if (params?.school_id) {
          urlObj.searchParams.append('school_id', params?.school_id);
        }

        if (params?.user_id) {
          urlObj.searchParams.append('user_id', params?.user_id);
        }

        return urlObj.toString();
      },
    },
    assignSchoolToUser: {
      url: `${v1Base}/schoolofuser`,
      method: 'post',
    },

    dissociateSchoolAndUser: {
      url: (params) => `${v1Base}/schoolofuser/deleteschoolofuser?id=${params}`,
      method: 'post',
    },
  },
  admin: {
    client: {
      fetch: {
        method: 'get',
        url: (params) => {
          let urlObj = new URL(`${v1Base}/admin/client`);
          if (params?.id !== undefined) {
            urlObj.searchParams.append('id', params.id);
          }

          return urlObj.toString();
        },
      },
    },
    clientTypes: {
      method: 'get',
      url: `${v1Base}/admin/clientTypes`,
    },
    getOrganizationalUnit: {
      method: 'get',
      url: (params) => {
        let urlObj = new URL(`${v1Base}/admin/getOrganizationalUnit`);
        if (params?.userId !== undefined) {
          urlObj.searchParams.append('user_id', params.userId);
        }

        if (params?.clientId !== undefined) {
          urlObj.searchParams.append('client_id', params.clientId);
        }

        if (params?.schoolId !== undefined) {
          urlObj.searchParams.append('id', params.schoolId);
        }

        return urlObj.toString();
      },
    },
    schoolTypes: {
      method: 'get',
      url: `${v1Base}/admin/schooltypes`,
    },
    insertOrganizationalUnit: {
      method: 'post',
      url: `${v1Base}/admin/insertOrganizationalUnit`,
    },
    updateOrganizationalUnit: {
      method: 'post',
      url: `${v1Base}/admin/updateOrganizationalUnit`,
    },
    getUsersOfOrgUnit: {
      method: 'get',
      url: (params) => `${v1Base}/admin/getUsersOfOrgUnit?org_unit_id=${params.org_unit_id}`,
    }
  },
  client: {
    update: {
      method: 'post',
      url: `${v1Base}/client/update`,
    },
    addNew: {
      method: 'post',
      url: `${v1Base}/client`,
    },
  },
  state: {
    method: 'get',
    url: `${v1Base}/state`,
  },
  schoolDistrict: {
    method: 'get',
    url: (params) => {
      let urlObj = new URL(`${v1Base}/schooldistrict`,);
      if (params?.county_id !== undefined) {
        urlObj.searchParams.append('county_id', params.county_id);
      }

      return urlObj.toString();
    },
  },
  county: {
    method: 'get',
    url: (params) => {
      let urlObj = new URL(`${v1Base}/county`);
      if (params?.state_id !== undefined) {
        urlObj.searchParams.append('state_id', params?.state_id);
      }

      return urlObj.toString();
    },
  },
  usSchool: {
    method: 'get',
    url: (params) => {
      let urlObj = new URL(`${v1Base}/usschool`);
      if (params?.district_id !== undefined) {
        urlObj.searchParams.append('district_id', params?.district_id);
      }

      return urlObj.toString();
    },
  },
  taxonomyConfiguration: {
    addSubcategory: {
      method: 'post',
      url: `${v1Base}/taxonomyConfiguration/subcategory`,
    },
    updateSubcategory: {
      method: 'put',
      url: `${v1Base}/taxonomyConfiguration/subcategory`,
    },
    addItem: {
      method: 'post',
      url: `${v1Base}/taxonomyConfiguration/item`,
    },
    updateItem: {
      method: 'put',
      url: `${v1Base}/taxonomyConfiguration/item`,
    },
    downloadItem: {
      method: 'get',
      url: (params) => `${v1Base}/taxonomyconfiguration/item/download?id=${params?.id}`,
    },
    deleteItem: {
      method: 'delete',
      url: (params) => `${v1Base}/taxonomyconfiguration/item?id=${params?.id}`,
    },
    fetch: {
      method: 'get',
      url: (params) => `${v1Base}/taxonomyconfiguration/subcategory?id=${params?.id}`,
    },
    fetchItemDetailsById: {
      method: 'get',
      url: (params) => `${v1Base}/taxonomyconfiguration/item?id=${params?.id}`,
    }
  },
  addSchoolToUser: {
    method: 'post',
    url: `${v1Base}/orgunitofuser`,
  },
  addUserToSchool: {
    method: 'post',
    url: `${v1Base}/orgunitofuser/users`,
  },
  deleteUserOfOrgUnit: {
    method: 'delete',
    url: (params) => {
      let urlObj = new URL(`${v1Base}/orgunitofuser`);
      if (params?.userId !== undefined) {
        urlObj.searchParams.append('user_id', params.userId);
      }

      if (params?.orgUnitId !== undefined) {
        urlObj.searchParams.append('org_unit_id', params.orgUnitId);
      }

      return urlObj.toString();
    }
  },
  orgUnitOfUser: {
    fetchOrgUnitsOfUser: {
      method: 'get',
      url: (params) => `${v1Base}/orgunitofuser?user_id=${params?.userId}`,
    },
    deleteOrgUnitOfUser: {
      method: 'delete',
      url: (params) => {
        let urlObj = new URL(`${v1Base}/orgunitofuser`);
        if (params?.userId !== undefined) {
          urlObj.searchParams.append('user_id', params.userId);
        }

        if (params?.orgUnitId !== undefined) {
          urlObj.searchParams.append('org_unit_id', params.orgUnitId);
        }

        return urlObj.toString();
      }
    }
  }

};
