import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {
  PrimaryButtonComponent
} from "./components/buttons/primary-button/primary-button.component";
import {
  DialogBoxComponent
} from "./components/dialogbox/basic-dialog/dialog-box.component";
import {CdkConnectedOverlay, CdkOverlayOrigin} from "@angular/cdk/overlay";
import {MaterialModule} from "./material-module";
import {
  ConfirmationDialogComponent
} from "./components/dialogbox/confirmation-dialog/confirmation-dialog.component";
import {
  NumberInputComponent
} from "./components/form-fields/input/number-input/number-input.component";
import {
  PasswordInputComponent
} from "./components/form-fields/input/password-input/password-input.component";
import {
  PhoneNumberInputComponent
} from "./components/form-fields/input/phone-number-input/phone-number-input.component";
import {
  RadioInputComponent
} from "./components/form-fields/input/radio-input/radio-input.component";
import {
  TextAreaInputComponent
} from "./components/form-fields/input/text-area-input/text-area-input.component";
import {
  SearchOnSelectComponent
} from "./components/form-fields/search-on-select/search-on-select.component";
import {IconComponent} from "./components/icon/icon.component";
import {ImageComponent} from "./components/image/image.component";
import {
  ShimmerLoaderComponent
} from "./components/shimmer-loader/shimmer-loader.component";
import {DateDirective} from "./directive/date.directive";
import {DatetimeDirective} from "./directive/datetime.directive";
import {
  InputRestrictionDirective
} from "./directive/input-restriction.directive";
import {OnlyDigitsDirective} from "./directive/only-digits.directive";
import {
  PreventMultipleClickDirective
} from "./directive/prevent-multiple-click.directive";
import {PhoneNumberDirective} from "./directive/phone-number.directive";
import {DateFormatPipe} from "./pipes/date-format.pipe";
import {DatetimeFormatPipe} from "./pipes/datetime-format.pipe";
import {PhoneNumberFormatPipe} from "./pipes/phone-number-format.pipe";
import {SafeHTMLPipe} from "./pipes/safe-html.pipe";
import {SafeUrlPipe} from "./pipes/safe-url.pipe";
import {SharedEffects} from "./core/shared.effects";
import {StoreModule} from "@ngrx/store";
import {reducer} from "./core/shared.reducer";
import {EffectsModule} from "@ngrx/effects";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {
  UnauthenticatedWrapperComponent
} from './components/wrappers/unauthenticated-wrapper/unauthenticated-wrapper.component';
import {RouterLink, RouterOutlet} from "@angular/router";
import {
  AuthenticatedWrapperComponent
} from './components/wrappers/authenticated-wrapper/authenticated-wrapper.component';
import {
  TextInputComponent
} from "./components/form-fields/input/text-input/text-input.component";
import {
  DropdownComponent
} from "./components/form-fields/dropdown/dropdown.component";
import {MenuPageComponent} from './pages/menu-page/menu-page.component';
import {
  ExternalPageNotFoundComponent
} from "./pages/page-not-found/external-page-not-found/external-page-not-found.component";
import {
  InternalPageNotFoundComponent
} from './pages/page-not-found/internal-page-not-found/internal-page-not-found.component';
import {
  LoadingScreenComponent
} from './components/loading-screen/loading-screen.component';
import {
  SidenavContainerComponent
} from './components/sidenav-container/sidenav-container.component';
import {
  VisualizeJsComponent
} from './components/visualize-js/visualize-js.component';
import {ScrollToZoomDirective} from './directive/scrollToZoom.directive';
import {
  MenuSidenavComponent
} from './components/menu-sidenav/menu-sidenav.component';
import {
  SecondaryButtonComponent
} from './components/buttons/secondary-button/secondary-button.component';
import {
  CheckboxComponent
} from "./components/form-fields/input/checkbox/checkbox.component";
import {
  AddOrEditUserComponent
} from "./components/add-or-edit-user/add-or-edit-user.component";
import {
  AddOrEditClientComponent
} from "./components/add-or-edit-client/add-or-edit-client.component";
import {
  AddOrEditSchoolComponent
} from "./components/add-or-edit-school/add-or-edit-school.component";
import { GoogleTranslateComponent } from './components/google-translate/google-translate.component';
import { TranslationSelectorComponent } from './components/translation-selector/translation-selector.component';
import {InfiniteScrollModule} from "ngx-infinite-scroll";

@NgModule({
  declarations: [
    PrimaryButtonComponent,
    DialogBoxComponent,
    ConfirmationDialogComponent,
    NumberInputComponent,
    PasswordInputComponent,
    PhoneNumberInputComponent,
    RadioInputComponent,
    TextAreaInputComponent,
    SearchOnSelectComponent,
    IconComponent,
    ImageComponent,
    ShimmerLoaderComponent,
    DateDirective,
    DatetimeDirective,
    InputRestrictionDirective,
    OnlyDigitsDirective,
    PreventMultipleClickDirective,
    PhoneNumberDirective,
    DateFormatPipe,
    DatetimeFormatPipe,
    PhoneNumberFormatPipe,
    SafeHTMLPipe,
    SafeUrlPipe,
    UnauthenticatedWrapperComponent,
    AuthenticatedWrapperComponent,
    TextInputComponent,
    DropdownComponent,
    MenuPageComponent,
    ExternalPageNotFoundComponent,
    InternalPageNotFoundComponent,
    LoadingScreenComponent,
    SidenavContainerComponent,
    VisualizeJsComponent,
    ScrollToZoomDirective,
    MenuSidenavComponent,
    SecondaryButtonComponent,
    CheckboxComponent,
    AddOrEditUserComponent,
    AddOrEditClientComponent,
    AddOrEditSchoolComponent,
    GoogleTranslateComponent,
    TranslationSelectorComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    StoreModule.forFeature('school-voices.shared', reducer),
    EffectsModule.forFeature(
      [SharedEffects]
    ),
    FormsModule,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    NgxSkeletonLoaderModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    RouterOutlet,
    RouterLink,
    InfiniteScrollModule
  ],
  exports: [
    MaterialModule,
    UnauthenticatedWrapperComponent,
    TextInputComponent,
    PhoneNumberInputComponent,
    NumberInputComponent,
    PasswordInputComponent,
    RadioInputComponent,
    TextAreaInputComponent,
    ImageComponent,
    PrimaryButtonComponent,
    IconComponent,
    DropdownComponent,
    VisualizeJsComponent,
    SecondaryButtonComponent,
    CheckboxComponent,
    SearchOnSelectComponent,
    AddOrEditUserComponent,
    AddOrEditClientComponent,
    AddOrEditSchoolComponent
  ],
  providers: [
    DatePipe,
    DateFormatPipe,
    PhoneNumberFormatPipe,
  ]
})
export class SharedModule {}
