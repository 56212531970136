/* NgRx */
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SharedState} from './shared.reducer';

// Selector functions
const sharedData = createFeatureSelector<SharedState>('school-voices.shared');

export const isLoading = createSelector(sharedData, (state) => state.loading);
export const screenType = createSelector(
  sharedData,
  (state: SharedState) => state.screenType
);

export const profile = createSelector(
  sharedData,
  (state: SharedState) => state.profile
);

export const clientId = createSelector(
  sharedData,
  (state: SharedState) => state.clientId
);

export const sidenavOpenCounter = createSelector(
  sharedData,
  (state: SharedState) => state.sidenavOpenCounter
);

export const countyFilter = createSelector(
  sharedData,
  (state: SharedState) => state.countyFilter
);

export const organizationTypeFilter = createSelector(
  sharedData,
  (state: SharedState) => state.organizationTypeFilter
);

export const schoolDistrictFilter = createSelector(
  sharedData,
  (state: SharedState) => state.schoolDistrictFilter
);

export const schoolFilter = createSelector(
  sharedData,
  (state: SharedState) => state.schoolFilter
);

export const stateFilter = createSelector(
  sharedData,
  (state: SharedState) => state.stateFilter
);

export const roleFilter = createSelector(
  sharedData,
  (state: SharedState) => state.roleFilter
);

export const surveyLanguageData = createSelector(
  sharedData,
  (state: SharedState) => state.surveyLanguageData
);

export const clientFilter = createSelector(
  sharedData,
  (state: SharedState) => state.clientFilter
);

export const schoolTypesFilter = createSelector(
  sharedData,
  (state: SharedState) => state.schoolTypesFilter
);

export const productTierFilter = createSelector(
  sharedData,
  (state: SharedState) => state.productTierFilter
);
