import {Component, Input, OnInit} from '@angular/core';
import {NgxSkeletonLoaderConfig} from "ngx-skeleton-loader";

@Component({
  selector: 'app-shimmer-loader',
  templateUrl: './shimmer-loader.component.html',
  styleUrls: ['./shimmer-loader.component.css']
})
export class ShimmerLoaderComponent implements OnInit {
  @Input() count: number = 1;
  @Input() appearance: NgxSkeletonLoaderConfig["appearance"] = 'line';
  @Input() theme: any;

  constructor() {
  }

  ngOnInit(): void {
  }
}
