import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';

import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import * as fromRoot from '../../../state/app.state';
import {CommonService} from '../services/common.service';
import {
  AddNewClientFailure,
  AddNewClientRequest,
  AddNewClientSuccess,
  AddNewSchoolFailure,
  AddNewSchoolRequest,
  AddNewSchoolSuccess,
  AddNewUserFailure,
  AddNewUserRequest,
  AddNewUserSuccess,
  FetchClientFilterFailure,
  FetchClientFilterRequest,
  FetchClientFilterSuccess,
  FetchCountyFilterFailure,
  FetchCountyFilterRequest,
  FetchCountyFilterSuccess,
  FetchOrganizationTypeFilterFailure,
  FetchOrganizationTypeFilterRequest,
  FetchOrganizationTypeFilterSuccess,
  FetchProductTierFilterFailure,
  FetchProductTierFilterRequest,
  FetchProductTierFilterSuccess,
  FetchRoleFilterFailure,
  FetchRoleFilterRequest,
  FetchRoleFilterSuccess,
  FetchSchoolDistrictFilterFailure,
  FetchSchoolDistrictFilterRequest,
  FetchSchoolDistrictFilterSuccess,
  FetchSchoolFilterFailure,
  FetchSchoolFilterRequest,
  FetchSchoolFilterSuccess,
  FetchSchoolTypesFilterFailure,
  FetchSchoolTypesFilterRequest,
  FetchSchoolTypesFilterSuccess,
  FetchStateFilterFailure,
  FetchStateFilterRequest,
  FetchStateFilterSuccess,
  RefreshTokenFailure,
  RefreshTokenRequest,
  RefreshTokenSuccess,
  UpdateClientFailure,
  UpdateClientRequest,
  UpdateClientSuccess,
  UpdateSchoolFailure,
  UpdateSchoolRequest,
  UpdateSchoolSuccess,
  UpdateUserFailure,
  UpdateUserRequest,
  UpdateUserSuccess,
} from './shared.actions';
import {SharedService} from './shared.service';
import {profile} from "./shared.selectors";
import {roles} from '../constants/common.constants';

@Injectable()
export class SharedEffects {
  refreshToken: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(RefreshTokenRequest),
      mergeMap(() =>
        this.sharedService.refreshToken().pipe(
          map((response: any) => {
            const accessToken: string = response.access_token;
            const refreshToken: string = response.refresh_token;

            this.commonService.setAuthenticationToken(accessToken);
            this.commonService.setRefreshToken(refreshToken);

            return RefreshTokenSuccess();
          }),
          catchError(() => {
            return of(RefreshTokenFailure());
          }),
          tap((action: any) => {
            if (action.type === RefreshTokenSuccess.type) {
              // Code to execute on API Success Action dispatch
              location.reload();
            } else if (action.type === RefreshTokenFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  fetchCountyFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchCountyFilterRequest),
      map((action: any) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.fetchCountyFilter(payload).pipe(
          map((response) => {

            const {data, message} = response;
            const tempData = data.map((item) => {
              return {
                key: item.id,
                value: item.label,
              };
            });
            return FetchCountyFilterSuccess({
              countyFilter: tempData,
              message,
            });
          }),
          catchError((error) => {
            return of(FetchCountyFilterFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === FetchCountyFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchCountyFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  fetchOrganizationTypeFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchOrganizationTypeFilterRequest),
      map((action: any) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.fetchOrganizationTypeFilter(payload).pipe(
          map((response) => {
            const {data, message} = response;
            const tempData = data.map((item) => {
              return {
                key: item.id,
                value: item.value,
              };
            });
            return FetchOrganizationTypeFilterSuccess({
              organizationTypeFilter: tempData,
              message,
            });
          }),
          catchError((error) => {
            return of(FetchOrganizationTypeFilterFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === FetchOrganizationTypeFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (
              action.type === FetchOrganizationTypeFilterFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  fetchSchoolDistrictFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchSchoolDistrictFilterRequest),
      map((action: any) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.fetchSchoolDistrictFilter(payload).pipe(
          map((response) => {
            const {data, message} = response;
            const tempData = data.map((item) => {
              return {
                key: item.id,
                value: item.label,
              };
            });
            return FetchSchoolDistrictFilterSuccess({
              schoolDistrictFilter: tempData,
              message,
            });
          }),
          catchError((error) => {
            return of(FetchSchoolDistrictFilterFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === FetchSchoolDistrictFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchSchoolDistrictFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  fetchSchoolFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchSchoolFilterRequest),
      map((action: any) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.fetchSchoolFilter(payload).pipe(
          map((response) => {
            const {data, message} = response;
            const tempData = data.map((item) => {
              return {
                key: item.school_code,
                value: item.name,
              }
            })
            return FetchSchoolFilterSuccess({
              schoolFilter: tempData,
              message,
            });
          }),
          catchError((error) => {
            return of(FetchSchoolFilterFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === FetchSchoolFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchSchoolFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  fetchStateFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchStateFilterRequest),
      map((action: any) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.fetchStateFilter(payload).pipe(
          map((response) => {
            const {data, message} = response;
            const tempData = data.map((item) => {
              return {
                key: item.id,
                value: item.name,
              };
            });
            return FetchStateFilterSuccess({
              stateFilter: tempData,
              message,
            });
          }),
          catchError((error) => {
            return of(FetchStateFilterFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === FetchStateFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchStateFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  updateClient$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateClientRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.updateClient(payload).pipe(
          map((response) => {
            return UpdateClientSuccess({
              message: response.data
            });
          }),
          catchError((error) => {
            return of(UpdateClientFailure(error.message));
          }),
          tap((action: any) => {
              if (action.type === UpdateClientSuccess.type) {
                // Code to execute on API Success Action dispatch
                this.commonService.notification(action.message, 'success');
                this.commonService.closeDialog();
              } else if (action.type === UpdateClientFailure.type) {
                // Code to execute on API Failure Action dispatch
              }
              this.commonService.stopLoading();
            }
          )
        )
      )
    )
  );

  addNewClient$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AddNewClientRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.addNewClient(payload).pipe(
          map((response) => {
            return AddNewClientSuccess({
              message: response.data
            });
          }),
          catchError((error) => {
            return of(AddNewClientFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === AddNewClientSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.notification(action.message, 'success');
              this.commonService.closeDialog();
            } else if (action.type === AddNewClientFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  fetchClientFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchClientFilterRequest),
      map((action: any) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.fetchClientFilter(payload).pipe(
          map((response) => {
            const {data, message} = response;
            const tempData = data.map((item) => {
              return {
                key: item.id,
                value: item.name,
              };
            });
            return FetchClientFilterSuccess({
              clientFilter: tempData,
              message,
            });
          }),
          catchError((error) => {
            return of(FetchClientFilterFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === FetchClientFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchClientFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  updateUser$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateUserRequest),
      map((action: any) => {

        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.updateUser(payload).pipe(
          map((response) => {

            return UpdateUserSuccess({
              message: response.data
            });
          }),
          catchError((error) => {
            return of(UpdateUserFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === UpdateUserSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.closeDialog();
              this.commonService.notification(action.message, 'success');
            } else if (action.type === UpdateUserFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  addNewUser$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AddNewUserRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.addNewUser(payload).pipe(
          map((response) => {
            return AddNewUserSuccess({
              message: response.data
            });
          }),
          catchError((error) => {
            return of(AddNewUserFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === AddNewUserSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.notification(action.message, 'success');
              this.commonService.closeDialog();
            } else if (action.type === AddNewUserFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  updateSchool$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateSchoolRequest),
      map((action: any) => {

        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.updateSchool(payload).pipe(
          map((response) => {

            return UpdateSchoolSuccess({
              message: response.data
            });
          }),
          catchError((error) => {
            return of(UpdateSchoolFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === UpdateSchoolSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.closeDialog();
              this.commonService.notification(action.message, 'success')
            } else if (action.type === UpdateSchoolFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  addNewSchool$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AddNewSchoolRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.addNewSchool(payload).pipe(
          map((response) => {
            return AddNewSchoolSuccess({
              message: response.data
            });
          }),
          catchError((error) => {
            return of(AddNewSchoolFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === AddNewSchoolSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.notification(action.message, 'success');
              this.commonService.closeDialog();
            } else if (action.type === AddNewSchoolFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  fetchSchoolTypesFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchSchoolTypesFilterRequest),
      map((action: any) => {
        return action.payload;
      }),
      mergeMap(() =>
        this.sharedService.fetchSchoolTypesFilter().pipe(
          map((response) => {
            const {data, message} = response;
            const tempData = data.map((item) => {
              return {
                key: item.school_type_combined_id,
                value: item.school_type_combined,
              }
            })
            return FetchSchoolTypesFilterSuccess({
              schoolTypesFilter: tempData,
              message,
            });
          }),
          catchError((error) => {
            return of(FetchSchoolTypesFilterFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === FetchSchoolTypesFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchSchoolTypesFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  role: any;
  fetchRoleFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchRoleFilterRequest),
      map((action: any) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.fetchRoleFilter(payload).pipe(
          map((response) => {
            let tempResponse = response.map((item) => {
              return {
                key: item.name,
                value: item.name,
              };
            });

            this.store.select(profile)
              .subscribe((data) => {
                this.role = data?.role;
              }).unsubscribe()

            if (this.role === roles.clientAdmin) {
              tempResponse = tempResponse.filter(item => item.key !== roles.superAdmin);
            }

            return FetchRoleFilterSuccess({
              roleFilter: tempResponse,
            });
          }),
          catchError((error) => {
            return of(FetchRoleFilterFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === FetchRoleFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchRoleFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  fetchProductTierFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchProductTierFilterRequest),
      map((action: any) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.fetchProductTierFilter(payload).pipe(
          map((response) => {
            const {data} = response;
            let tempResponse = data.map((item) => {
              return {
                key: item.id,
                value: item.value,
              };
            });
            return FetchProductTierFilterSuccess({
              productTierFilter: tempResponse,
            });
          }),
          catchError((error) => {
            return of(FetchProductTierFilterFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === FetchProductTierFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchProductTierFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  constructor(
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
    private sharedService: SharedService,
    private actions: Actions
  ) {
  }
}
