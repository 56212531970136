<div
  class="basic-dialog"
>
  <div class="heading-section flex flex-row justify-center items-center" mat-dialog-title>
    <div class="heading">
      {{ dialogHeading }}
    </div>
    <div
      class="close-icon-panel flex justify-center items-center"
    >
      <mat-icon (click)="closeDialog()" class="close-icon">close</mat-icon>
    </div>
  </div>

  <div
    class="container-component {{showActionSection ? 'h-[calc(100%-11.5rem)]' : 'h-[calc(100%-5.2rem)]'}}"
    mat-dialog-content
  >
    <template #anchor></template>
  </div>
  <div
    *ngIf="showActionSection"
    class="action-section"
  >
    <mat-dialog-actions align="end">
      <app-primary-button
        (handleClick)="accept()"
        class="button xs:w-full md:w-1/4 mr-[2rem] mt-[1rem]"
      >
        Accept
      </app-primary-button>
    </mat-dialog-actions>
  </div>
</div>

