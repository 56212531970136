/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[schools list page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/*********** FETCH CLIENT FILTER **************/
const FETCH_CLIENT_FILTER_REQUEST = '[schools list page] fetch client filter requested';
export const FetchClientFilterRequest = createAction(FETCH_CLIENT_FILTER_REQUEST, props<any>());

const FETCH_CLIENT_FILTER_SUCCESS = '[schools list page] fetch client filter successful';
export const FetchClientFilterSuccess = createAction(FETCH_CLIENT_FILTER_SUCCESS, props<any>());

const FETCH_CLIENT_FILTER_FAILURE = '[schools list page] fetch client filter failure';
export const FetchClientFilterFailure = createAction(FETCH_CLIENT_FILTER_FAILURE, props<any>());

/*********** FETCH COUNTY FILTER **************/
const FETCH_COUNTY_FILTER_REQUEST = '[schools list page] fetch county filter requested';
export const FetchCountyFilterRequest = createAction(FETCH_COUNTY_FILTER_REQUEST, props<any>());

const FETCH_COUNTY_FILTER_SUCCESS = '[schools list page] fetch county filter successful';
export const FetchCountyFilterSuccess = createAction(FETCH_COUNTY_FILTER_SUCCESS, props<any>());

const FETCH_COUNTY_FILTER_FAILURE = '[schools list page] fetch county filter failure';
export const FetchCountyFilterFailure = createAction(FETCH_COUNTY_FILTER_FAILURE, props<any>());

/*********** FETCH ORGANIZATION TYPE FILTER **************/
const FETCH_ORGANIZATION_TYPE_FILTER_REQUEST = '[schools list page] fetch organization type filter requested';
export const FetchOrganizationTypeFilterRequest = createAction(FETCH_ORGANIZATION_TYPE_FILTER_REQUEST, props<any>());

const FETCH_ORGANIZATION_TYPE_FILTER_SUCCESS = '[schools list page] fetch organization type filter successful';
export const FetchOrganizationTypeFilterSuccess = createAction(FETCH_ORGANIZATION_TYPE_FILTER_SUCCESS, props<any>());

const FETCH_ORGANIZATION_TYPE_FILTER_FAILURE = '[schools list page] fetch organization type filter failure';
export const FetchOrganizationTypeFilterFailure = createAction(FETCH_ORGANIZATION_TYPE_FILTER_FAILURE, props<any>());

/*********** FETCH SCHOOL DISTRICT FILTER **************/
const FETCH_SCHOOL_DISTRICT_FILTER_REQUEST = '[schools list page] fetch school district filter requested';
export const FetchSchoolDistrictFilterRequest = createAction(FETCH_SCHOOL_DISTRICT_FILTER_REQUEST, props<any>());

const FETCH_SCHOOL_DISTRICT_FILTER_SUCCESS = '[schools list page] fetch school district filter successful';
export const FetchSchoolDistrictFilterSuccess = createAction(FETCH_SCHOOL_DISTRICT_FILTER_SUCCESS, props<any>());

const FETCH_SCHOOL_DISTRICT_FILTER_FAILURE = '[schools list page] fetch school district filter failure';
export const FetchSchoolDistrictFilterFailure = createAction(FETCH_SCHOOL_DISTRICT_FILTER_FAILURE, props<any>());

/*********** FETCH SCHOOL FILTER **************/
const FETCH_SCHOOL_FILTER_REQUEST = '[schools list page] fetch school filter requested';
export const FetchSchoolFilterRequest = createAction(FETCH_SCHOOL_FILTER_REQUEST, props<any>());

const FETCH_SCHOOL_FILTER_SUCCESS = '[schools list page] fetch school filter successful';
export const FetchSchoolFilterSuccess = createAction(FETCH_SCHOOL_FILTER_SUCCESS, props<any>());

const FETCH_SCHOOL_FILTER_FAILURE = '[schools list page] fetch school filter failure';
export const FetchSchoolFilterFailure = createAction(FETCH_SCHOOL_FILTER_FAILURE, props<any>());

/*********** FETCH SCHOOLS LIST **************/
const FETCH_SCHOOLS_LIST_REQUEST = '[schools list page] fetch schools list requested';
export const FetchSchoolsListRequest = createAction(FETCH_SCHOOLS_LIST_REQUEST, props<any>());

const FETCH_SCHOOLS_LIST_SUCCESS = '[schools list page] fetch schools list successful';
export const FetchSchoolsListSuccess = createAction(FETCH_SCHOOLS_LIST_SUCCESS, props<any>());

const FETCH_SCHOOLS_LIST_FAILURE = '[schools list page] fetch schools list failure';
export const FetchSchoolsListFailure = createAction(FETCH_SCHOOLS_LIST_FAILURE, props<any>());

/*********** FETCH STATE FILTER **************/
const FETCH_STATE_FILTER_REQUEST = '[schools list page] fetch state filter requested';
export const FetchStateFilterRequest = createAction(FETCH_STATE_FILTER_REQUEST, props<any>());

const FETCH_STATE_FILTER_SUCCESS = '[schools list page] fetch state filter successful';
export const FetchStateFilterSuccess = createAction(FETCH_STATE_FILTER_SUCCESS, props<any>());

const FETCH_STATE_FILTER_FAILURE = '[schools list page] fetch state filter failure';
export const FetchStateFilterFailure = createAction(FETCH_STATE_FILTER_FAILURE, props<any>());

/******** UPDATE SCHOOL ***********/
const UPDATE_SCHOOL_REQUEST = '[schools list page] update school request';
export const UpdateSchoolRequest = createAction(
  UPDATE_SCHOOL_REQUEST,
  props<any>()
);

const UPDATE_SCHOOL_SUCCESS = '[schools list page] update school success';
export const UpdateSchoolSuccess = createAction(
  UPDATE_SCHOOL_SUCCESS,
  props<any>()
);

const UPDATE_SCHOOL_FAILURE = '[schools list page] update school failure';
export const UpdateSchoolFailure = createAction(
  UPDATE_SCHOOL_FAILURE,
  props<any>()
);
