import {createReducer, on} from '@ngrx/store';
import {
  ClearLoading,
  ClearSidenavCounter,
  ClearState,
  ClearSurveyLanguageData,
  FetchClientFilterFailure,
  FetchClientFilterRequest,
  FetchClientFilterSuccess,
  FetchCountyFilterFailure,
  FetchCountyFilterRequest,
  FetchCountyFilterSuccess,
  FetchOrganizationTypeFilterFailure,
  FetchOrganizationTypeFilterRequest,
  FetchOrganizationTypeFilterSuccess,
  FetchProductTierFilterFailure,
  FetchProductTierFilterRequest,
  FetchProductTierFilterSuccess,
  FetchRoleFilterFailure,
  FetchRoleFilterRequest,
  FetchRoleFilterSuccess,
  FetchSchoolDistrictFilterFailure,
  FetchSchoolDistrictFilterRequest,
  FetchSchoolDistrictFilterSuccess,
  FetchSchoolFilterFailure,
  FetchSchoolFilterRequest,
  FetchSchoolFilterSuccess,
  FetchSchoolTypesFilterFailure,
  FetchSchoolTypesFilterRequest,
  FetchSchoolTypesFilterSuccess,
  FetchStateFilterFailure,
  FetchStateFilterRequest,
  FetchStateFilterSuccess,
  MarkSidenavClosed,
  MarkSidenavOpen,
  SetClientId,
  SetProfile,
  SetScreenType,
  SetSurveyLanguageData,
  StartLoading,
  StopLoading,
} from './shared.actions';
import {DropdownOption} from 'src/app/models/dropdown-option.model';

// State for this feature (User)
export interface SharedState {
  loading: number[];
  screenType: 'mobile' | 'tablet' | 'desktop' | '';
  profile: any;
  clientId: number;
  sidenavOpenCounter: number[];
  countyFilter: {
    list: DropdownOption[];
    loading: boolean;
  };
  organizationTypeFilter: {
    list: DropdownOption[];
    loading: boolean;
  };
  schoolDistrictFilter: {
    list: DropdownOption[];
    loading: boolean;
  };
  schoolFilter: {
    list: DropdownOption[];
    loading: boolean;
  };
  stateFilter: {
    list: DropdownOption[];
    loading: boolean;
  };
  roleFilter: {
    list: DropdownOption[];
    loading: boolean;
  };
  productTierFilter: {
    list: DropdownOption[];
    loading: boolean;
  };
  surveyLanguageData: {
    list: any[];
    defaultLanguage: number;
    showGoogleTranslate: boolean;
  };
  clientFilter: {
    list: DropdownOption[];
    loading: boolean;
  };
  schoolTypesFilter: {
    list: DropdownOption[];
    loading: boolean;
  };
}

const initialState: SharedState = {
  loading: [],
  screenType: '',
  profile: null,
  clientId: null,
  sidenavOpenCounter: [],
  countyFilter: {
    list: [],
    loading: false,
  },
  organizationTypeFilter: {
    list: [],
    loading: false,
  },
  schoolDistrictFilter: {
    list: [],
    loading: false,
  },
  schoolFilter: {
    list: [],
    loading: false,
  },
  stateFilter: {
    list: [],
    loading: false,
  },
  roleFilter: {
    list: [],
    loading: false,
  },
  productTierFilter: {
    list: [],
    loading: false,
  },
  surveyLanguageData: {
    list: [],
    defaultLanguage: null,
    showGoogleTranslate: true,
  },
  clientFilter: {
    list: [],
    loading: false,
  },
  schoolTypesFilter: {
    list: [],
    loading: false,
  }
};

export const reducer = createReducer(
  initialState,

  // CLEAR STATE
  on(ClearState, (state) => ({
    ...initialState,
    screenType: state.screenType,
  })),

  // SET SCREEN TYPE
  on(SetScreenType, (state, props) => ({
    ...state,
    screenType: props.screenType,
  })),

  // SET PROFILE
  on(SetProfile, (state, props) => ({
    ...state,
    profile: props.profile,
  })),

  // SET CLIENT ID
  on(SetClientId, (state, props) => ({
    ...state,
    clientId: props.clientId,
  })),

  // CLEAR SURVEY LANGUAGE OPTIONS
  on(ClearSurveyLanguageData, (state) => ({
    ...state,
    surveyLanguageData: initialState.surveyLanguageData,
  })),

  // SET SURVEY LANGUAGE OPTIONS
  on(SetSurveyLanguageData, (state, props) => ({
    ...state,
    surveyLanguageData: props.payload,
  })),

  // START LOADING
  on(StartLoading, (state) => ({
    ...state,
    loading: [...state.loading, 1],
  })),

  // STOP LOADING
  on(StopLoading, (state) => ({
    ...state,
    loading: [...state.loading].slice(1),
  })),

  // CLEAR LOADING
  on(ClearLoading, (state) => ({
    ...state,
    loading: [],
  })),

  // MARK SIDENAV OPEN
  on(MarkSidenavOpen, (state) => ({
    ...state,
    sidenavOpenCounter:
      state.sidenavOpenCounter.length < 2
        ? [...state.sidenavOpenCounter, 1]
        : state.sidenavOpenCounter,
  })),

  // MARK SIDENAV CLOSED
  on(MarkSidenavClosed, (state) => ({
    ...state,
    sidenavOpenCounter: [...state.sidenavOpenCounter].slice(1),
  })),

  // CLEAR SIDENAV COUNTER
  on(ClearSidenavCounter, (state) => ({
    ...state,
    sidenavOpenCounter: [],
  })),

  // FETCH COUNTY FILTER
  on(FetchCountyFilterRequest, (state) => ({
    ...state,
    countyFilter: {
      ...initialState.countyFilter,
      loading: true,
    },
  })),
  on(FetchCountyFilterSuccess, (state, props) => ({
    ...state,
    countyFilter: {
      list: props.countyFilter,
      loading: false,
    },
  })),
  on(FetchCountyFilterFailure, (state) => ({
    ...state,
    countyFilter: {
      list: initialState.countyFilter.list,
      loading: false,
    },
  })),

  // FETCH ORGANIZATION TYPE FILTER
  on(FetchOrganizationTypeFilterRequest, (state) => ({
    ...state,
    organizationTypeFilter: {
      ...initialState.organizationTypeFilter,
      loading: true,
    },
  })),
  on(FetchOrganizationTypeFilterSuccess, (state, props) => ({
    ...state,
    organizationTypeFilter: {
      list: props.organizationTypeFilter,
      loading: false,
    },
  })),
  on(FetchOrganizationTypeFilterFailure, (state) => ({
    ...state,
    organizationTypeFilter: {
      ...initialState.organizationTypeFilter,
      loading: false,
    },
  })),

  // FETCH SCHOOL DISTRICT FILTER
  on(FetchSchoolDistrictFilterRequest, (state) => ({
    ...state,
    schoolDistrictFilter: {
      ...initialState.schoolDistrictFilter,
      loading: true,
    },
  })),
  on(FetchSchoolDistrictFilterSuccess, (state, props) => ({
    ...state,
    schoolDistrictFilter: {
      list: props.schoolDistrictFilter,
      loading: false,
    },
  })),
  on(FetchSchoolDistrictFilterFailure, (state) => ({
    ...state,
    schoolDistrictFilter: {
      ...initialState.schoolDistrictFilter,
      loading: false,
    },
  })),

  // FETCH SCHOOL FILTER
  on(FetchSchoolFilterRequest, (state) => ({
    ...state,
    schoolFilter: {
      ...initialState.schoolFilter,
      loading: true,
    },
  })),
  on(FetchSchoolFilterSuccess, (state, props) => ({
    ...state,
    schoolFilter: {
      list: props.schoolFilter,
      loading: false,
    },
  })),
  on(FetchSchoolFilterFailure, (state) => ({
    ...state,
    schoolFilter: {
      ...initialState.schoolFilter,
      loading: false,
    },
  })),

  // FETCH STATE FILTER
  on(FetchStateFilterRequest, (state) => ({
    ...state,
    stateFilter: {
      ...initialState.stateFilter,
      loading: true,
    },
  })),
  on(FetchStateFilterSuccess, (state, props) => ({
    ...state,
    stateFilter: {
      list: props.stateFilter,
      loading: false,
    },
  })),
  on(FetchStateFilterFailure, (state) => ({
    ...state,
    stateFilter: {
      ...initialState.stateFilter,
      loading: false,
    },
  })),

  // FETCH ROLE FILTER
  on(FetchRoleFilterRequest, (state) => ({
    ...state,
    roleFilter: {
      ...initialState.roleFilter,
      loading: true,
    },
  })),
  on(FetchRoleFilterSuccess, (state, props) => ({
    ...state,
    roleFilter: {
      list: props.roleFilter,
      loading: false,
    },
  })),
  on(FetchRoleFilterFailure, (state) => ({
    ...state,
    roleFilter: {
      ...initialState.roleFilter,
      loading: false,
    },
  })),

  // FETCH CLIENT FILTER
  on(FetchClientFilterRequest, (state) => ({
    ...state,
    clientFilter: {
      ...initialState.clientFilter,
      loading: true,
    },
  })),
  on(FetchClientFilterSuccess, (state, props) => ({
    ...state,
    clientFilter: {
      list: props.clientFilter,
      loading: false,
    },
  })),
  on(FetchClientFilterFailure, (state) => ({
    ...state,
    clientFilter: {
      ...initialState.clientFilter,
      loading: false,
    },
  })),

// FETCH SCHOOL TYPES FILTER
  on(FetchSchoolTypesFilterRequest, (state) => ({
    ...state,
    schoolTypesFilter: {
      ...initialState.schoolTypesFilter,
      loading: true,
    },
  })),
  on(FetchSchoolTypesFilterSuccess, (state, props) => ({
    ...state,
    schoolTypesFilter: {
      list: props.schoolTypesFilter,
      loading: false,
    },
  })),
  on(FetchSchoolTypesFilterFailure, (state) => ({
    ...state,
    schoolTypesFilter: {
      ...initialState.schoolTypesFilter,
      loading: false,
    },
  })),

// FETCH PRODUCT TIER FILTER
  on(FetchProductTierFilterRequest, (state) => ({
    ...state,
    productTierFilter: {
      ...initialState.productTierFilter,
      loading: true,
    },
  })),
  on(FetchProductTierFilterSuccess, (state, props) => ({
    ...state,
    productTierFilter: {
      list: props.productTierFilter,
      loading: false,
    },
  })),
  on(FetchProductTierFilterFailure, (state) => ({
    ...state,
    productTierFilter: {
      ...initialState.productTierFilter,
      loading: false,
    },
  }))
);
