/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[shared] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** SET SCREEN TYPE ***********/
const SET_SCREEN_TYPE = '[shared] set screen type';
export const SetScreenType = createAction(
  SET_SCREEN_TYPE,
  props<{
    screenType: 'mobile' | 'tablet' | 'desktop' | '';
  }>()
);

/******** SET CLIENT ID ***********/
const SET_CLIENT_ID = '[shared] set client id';
export const SetClientId = createAction(
  SET_CLIENT_ID,
  props<{ clientId: number }>()
);

/******** SET_SURVEY_LANGUAGE_DATA ***********/
const SET_SURVEY_LANGUAGE_DATA = '[survey page] set survey language data';
export const SetSurveyLanguageData = createAction(
  SET_SURVEY_LANGUAGE_DATA,
  props<{ payload: any }>()
);

/******** CLEAR_SURVEY_LANGUAGE_DATA ***********/
const CLEAR_SURVEY_LANGUAGE_DATA = '[survey page] clear survey language data';
export const ClearSurveyLanguageData = createAction(CLEAR_SURVEY_LANGUAGE_DATA);

/******** LOADING ***********/
const START_LOADING = '[shared] start loading';
export const StartLoading = createAction(START_LOADING);

const STOP_LOADING = '[shared] stop loading';
export const StopLoading = createAction(STOP_LOADING);

const CLEAR_LOADING = '[shared] clear loading';
export const ClearLoading = createAction(CLEAR_LOADING);

/******** SET PROFILE ***********/
const SET_PROFILE = '[shared] set profile';
export const SetProfile = createAction(SET_PROFILE, props<any>());

/******** MARK SIDENAV OPEN ***********/
const MARK_SIDENAV_OPEN = '[shared] mark sidenav open';
export const MarkSidenavOpen = createAction(MARK_SIDENAV_OPEN);

/******** MARK SIDENAV CLOSED ***********/
const MARK_SIDENAV_CLOSED = '[shared] mark sidenav closed';
export const MarkSidenavClosed = createAction(MARK_SIDENAV_CLOSED);

/******** CLEAR SIDENAV COUNTER ***********/
const CLEAR_SIDENAV_COUNTER = '[shared] clear sidenav counter';
export const ClearSidenavCounter = createAction(CLEAR_SIDENAV_COUNTER);

/******** REFRESH TOKEN ***********/
const REFRESH_TOKEN_REQUEST = '[SHARED] refresh token requested';
export const RefreshTokenRequest = createAction(REFRESH_TOKEN_REQUEST);

const REFRESH_TOKEN_SUCCESS = '[SHARED] refresh token successful';
export const RefreshTokenSuccess = createAction(REFRESH_TOKEN_SUCCESS);

const REFRESH_TOKEN_FAILURE = '[SHARED] refresh token failure';
export const RefreshTokenFailure = createAction(REFRESH_TOKEN_FAILURE);

/*********** FETCH COUNTY FILTER **************/
const FETCH_COUNTY_FILTER_REQUEST = '[shared] fetch county filter requested';
export const FetchCountyFilterRequest = createAction(
  FETCH_COUNTY_FILTER_REQUEST,
  props<any>()
);

const FETCH_COUNTY_FILTER_SUCCESS = '[shared] fetch county filter successful';
export const FetchCountyFilterSuccess = createAction(
  FETCH_COUNTY_FILTER_SUCCESS,
  props<any>()
);

const FETCH_COUNTY_FILTER_FAILURE = '[shared] fetch county filter failure';
export const FetchCountyFilterFailure = createAction(
  FETCH_COUNTY_FILTER_FAILURE,
  props<any>()
);

/*********** FETCH ORGANIZATION TYPE FILTER **************/
const FETCH_ORGANIZATION_TYPE_FILTER_REQUEST =
  '[shared] fetch organization type filter requested';
export const FetchOrganizationTypeFilterRequest = createAction(
  FETCH_ORGANIZATION_TYPE_FILTER_REQUEST,
  props<any>()
);

const FETCH_ORGANIZATION_TYPE_FILTER_SUCCESS =
  '[shared] fetch organization type filter successful';
export const FetchOrganizationTypeFilterSuccess = createAction(
  FETCH_ORGANIZATION_TYPE_FILTER_SUCCESS,
  props<any>()
);

const FETCH_ORGANIZATION_TYPE_FILTER_FAILURE =
  '[shared] fetch organization type filter failure';
export const FetchOrganizationTypeFilterFailure = createAction(
  FETCH_ORGANIZATION_TYPE_FILTER_FAILURE,
  props<any>()
);

/*********** FETCH SCHOOL DISTRICT FILTER **************/
const FETCH_SCHOOL_DISTRICT_FILTER_REQUEST =
  '[shared] fetch school district filter requested';
export const FetchSchoolDistrictFilterRequest = createAction(
  FETCH_SCHOOL_DISTRICT_FILTER_REQUEST,
  props<any>()
);

const FETCH_SCHOOL_DISTRICT_FILTER_SUCCESS =
  '[shared] fetch school district filter successful';
export const FetchSchoolDistrictFilterSuccess = createAction(
  FETCH_SCHOOL_DISTRICT_FILTER_SUCCESS,
  props<any>()
);

const FETCH_SCHOOL_DISTRICT_FILTER_FAILURE =
  '[shared] fetch school district filter failure';
export const FetchSchoolDistrictFilterFailure = createAction(
  FETCH_SCHOOL_DISTRICT_FILTER_FAILURE,
  props<any>()
);

/*********** FETCH SCHOOL FILTER **************/
const FETCH_SCHOOL_FILTER_REQUEST = '[shared] fetch school filter requested';
export const FetchSchoolFilterRequest = createAction(
  FETCH_SCHOOL_FILTER_REQUEST,
  props<any>()
);

const FETCH_SCHOOL_FILTER_SUCCESS = '[shared] fetch school filter successful';
export const FetchSchoolFilterSuccess = createAction(
  FETCH_SCHOOL_FILTER_SUCCESS,
  props<any>()
);

const FETCH_SCHOOL_FILTER_FAILURE = '[shared] fetch school filter failure';
export const FetchSchoolFilterFailure = createAction(
  FETCH_SCHOOL_FILTER_FAILURE,
  props<any>()
);

/*********** FETCH STATE FILTER **************/
const FETCH_STATE_FILTER_REQUEST = '[shared] fetch state filter requested';
export const FetchStateFilterRequest = createAction(
  FETCH_STATE_FILTER_REQUEST,
  props<any>()
);

const FETCH_STATE_FILTER_SUCCESS = '[shared] fetch state filter successful';
export const FetchStateFilterSuccess = createAction(
  FETCH_STATE_FILTER_SUCCESS,
  props<any>()
);

const FETCH_STATE_FILTER_FAILURE = '[shared] fetch state filter failure';
export const FetchStateFilterFailure = createAction(
  FETCH_STATE_FILTER_FAILURE,
  props<any>()
);

/*********** FETCH ROLE FILTER **************/
const FETCH_ROLE_FILTER_REQUEST = '[shared] fetch role filter requested';
export const FetchRoleFilterRequest = createAction(
  FETCH_ROLE_FILTER_REQUEST,
  props<any>()
);

const FETCH_ROLE_FILTER_SUCCESS = '[shared] fetch role filter successful';
export const FetchRoleFilterSuccess = createAction(
  FETCH_ROLE_FILTER_SUCCESS,
  props<any>()
);

const FETCH_ROLE_FILTER_FAILURE = '[shared] fetch role filter failure';
export const FetchRoleFilterFailure = createAction(
  FETCH_ROLE_FILTER_FAILURE,
  props<any>()
);

/******** UPDATE CLIENT ***********/
const UPDATE_CLIENT_REQUEST = '[shared] update client request';
export const UpdateClientRequest = createAction(
  UPDATE_CLIENT_REQUEST,
  props<any>()
);

const UPDATE_CLIENT_SUCCESS = '[shared] update client success';
export const UpdateClientSuccess = createAction(
  UPDATE_CLIENT_SUCCESS,
  props<any>()
);

const UPDATE_CLIENT_FAILURE = '[shared] update client failure';
export const UpdateClientFailure = createAction(
  UPDATE_CLIENT_FAILURE,
  props<any>()
);

/******** ADD NEW CLIENT ***********/
const ADD_NEW_CLIENT_REQUEST = '[shared] add new client request';
export const AddNewClientRequest = createAction(
  ADD_NEW_CLIENT_REQUEST,
  props<any>()
);

const ADD_NEW_CLIENT_SUCCESS = '[shared] add new client success';
export const AddNewClientSuccess = createAction(
  ADD_NEW_CLIENT_SUCCESS,
  props<any>()
);

const ADD_NEW_CLIENT_FAILURE = '[shared] add new client failure';
export const AddNewClientFailure = createAction(
  ADD_NEW_CLIENT_FAILURE,
  props<any>()
);

/*********** FETCH CLIENT FILTER **************/
const FETCH_CLIENT_FILTER_REQUEST = '[shared] fetch client filter requested';
export const FetchClientFilterRequest = createAction(
  FETCH_CLIENT_FILTER_REQUEST,
  props<any>()
);

const FETCH_CLIENT_FILTER_SUCCESS = '[shared] fetch client filter successful';
export const FetchClientFilterSuccess = createAction(
  FETCH_CLIENT_FILTER_SUCCESS,
  props<any>()
);

const FETCH_CLIENT_FILTER_FAILURE = '[shared] fetch client filter failure';
export const FetchClientFilterFailure = createAction(
  FETCH_CLIENT_FILTER_FAILURE,
  props<any>()
);

/******** UPDATE USER ***********/
const UPDATE_USER_REQUEST = '[shared] update user request';
export const UpdateUserRequest = createAction(
  UPDATE_USER_REQUEST,
  props<any>()
);

const UPDATE_USER_SUCCESS = '[shared] update user success';
export const UpdateUserSuccess = createAction(
  UPDATE_USER_SUCCESS,
  props<any>()
);

const UPDATE_USER_FAILURE = '[shared] update user failure';
export const UpdateUserFailure = createAction(
  UPDATE_USER_FAILURE,
  props<any>()
);

/******** ADD NEW USER ***********/
const ADD_NEW_USER_REQUEST = '[shared] add new user request';
export const AddNewUserRequest = createAction(
  ADD_NEW_USER_REQUEST,
  props<any>()
);

const ADD_NEW_USER_SUCCESS = '[shared] add new user success';
export const AddNewUserSuccess = createAction(
  ADD_NEW_USER_SUCCESS,
  props<any>()
);

const ADD_NEW_USER_FAILURE = '[shared] add new user failure';
export const AddNewUserFailure = createAction(
  ADD_NEW_USER_FAILURE,
  props<any>()
);

/******** UPDATE SCHOOL ***********/
const UPDATE_SCHOOL_REQUEST = '[shared] update school request';
export const UpdateSchoolRequest = createAction(
  UPDATE_SCHOOL_REQUEST,
  props<any>()
);

const UPDATE_SCHOOL_SUCCESS = '[shared] update school success';
export const UpdateSchoolSuccess = createAction(
  UPDATE_SCHOOL_SUCCESS,
  props<any>()
);

const UPDATE_SCHOOL_FAILURE = '[shared] update school failure';
export const UpdateSchoolFailure = createAction(
  UPDATE_SCHOOL_FAILURE,
  props<any>()
);

/******** ADD NEW SCHOOL ***********/
const ADD_NEW_SCHOOL_REQUEST = '[shared] add new school request';
export const AddNewSchoolRequest = createAction(
  ADD_NEW_SCHOOL_REQUEST,
  props<any>()
);

const ADD_NEW_SCHOOL_SUCCESS = '[shared] add new school success';
export const AddNewSchoolSuccess = createAction(
  ADD_NEW_SCHOOL_SUCCESS,
  props<any>()
);

const ADD_NEW_SCHOOL_FAILURE = '[shared] add new school failure';
export const AddNewSchoolFailure = createAction(
  ADD_NEW_SCHOOL_FAILURE,
  props<any>()
);

/*********** FETCH SCHOOL TYPES FILTER **************/
const FETCH_SCHOOL_TYPES_FILTER_REQUEST = '[shared] fetch school types filter requested';
export const FetchSchoolTypesFilterRequest = createAction(
  FETCH_SCHOOL_TYPES_FILTER_REQUEST,
  props<any>()
);

const FETCH_SCHOOL_TYPES_FILTER_SUCCESS = '[shared] fetch school types filter successful';
export const FetchSchoolTypesFilterSuccess = createAction(
  FETCH_SCHOOL_TYPES_FILTER_SUCCESS,
  props<any>()
);

const FETCH_SCHOOL_TYPES_FILTER_FAILURE = '[shared] fetch school types filter failure';
export const FetchSchoolTypesFilterFailure = createAction(
  FETCH_SCHOOL_TYPES_FILTER_FAILURE,
  props<any>()
);

/*********** FETCH PRODUCT TIER FILTER **************/
const FETCH_PRODUCT_TIER_FILTER_REQUEST = '[shared] fetch product tier filter requested';
export const FetchProductTierFilterRequest = createAction(
  FETCH_PRODUCT_TIER_FILTER_REQUEST,
  props<any>()
);

const FETCH_PRODUCT_TIER_FILTER_SUCCESS = '[shared] fetch product tier filter successful';
export const FetchProductTierFilterSuccess = createAction(
  FETCH_PRODUCT_TIER_FILTER_SUCCESS,
  props<any>()
);

const FETCH_PRODUCT_TIER_FILTER_FAILURE = '[shared] fetch product tier filter failure';
export const FetchProductTierFilterFailure = createAction(
  FETCH_PRODUCT_TIER_FILTER_FAILURE,
  props<any>()
);

