<div
  *ngIf="parentFormGroup.controls[controlName]"
  class="font-work-sans font-regular"
>
  <div
    class="form-field-label flex flex-row justify-between"
    [class.error]="!!error"
    *ngIf="label"
  >
    <div class="label">
      {{label}}
      <span
        *ngIf="isRequired"
        class="required-mark"
      >*</span>
    </div>
    <div
      *ngIf="displayRedirectLink"
      class="link underline text-digital-blue-light"
      (click)="redirect()">
      Forgot Password?
    </div>
  </div>
  <mat-form-field
    [formGroup]="parentFormGroup"
    appearance="outline"
    class="form-field w-full"
    hideRequiredMarker
  >
    <input
      #input
      class="input password-input"
      [class.text-black-400]="isDisabled"
      [formControlName]="controlName"
      [maxlength]="maxLength"
      [minLength]="minLength"
      [type]="showPassword ? 'text' : 'password'"
      matInput
      placeholder="{{placeholder}}"
    >
    <div
      matSuffix
      class="suffix-icon-section
              rounded-full bg-[#00B0F0]"
      (click)="togglePasswordVisibility()"
    >
      <app-icon
        [svgIcon]="suffixIcon"
        class="suffix-icon"
      >
      </app-icon>
    </div>
    <mat-error *ngIf="error">{{error}}</mat-error>
  </mat-form-field>
</div>
