/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[survey versions page] clear state';
export const ClearState = createAction(CLEAR_STATE);


/******** FETCH SCHOOL LIST ***********/
const FETCH_SCHOOLS_LIST_REQUEST = '[survey versions page] fetch schools list request';
export const FetchSchoolsListRequest = createAction(FETCH_SCHOOLS_LIST_REQUEST, props<{ payload: any }>());

const FETCH_SCHOOLS_LIST_SUCCESS = '[survey versions page] fetch school list success';
export const FetchSchoolsListSuccess = createAction(FETCH_SCHOOLS_LIST_SUCCESS, props<any>());

const FETCH_SCHOOLS_LIST_FAILURE = '[survey versions page] fetch schools list failure';
export const FetchSchoolsListFailure = createAction(FETCH_SCHOOLS_LIST_FAILURE);

/******** FETCH SCHOOL YEARS ***********/
const FETCH_SCHOOL_YEARS_REQUEST = '[survey versions page] fetch school years request';
export const FetchSchoolYearsRequest = createAction(FETCH_SCHOOL_YEARS_REQUEST, props<{ payload: any }>());

const FETCH_SCHOOL_YEARS_SUCCESS = '[survey versions page] fetch school years success';
export const FetchSchoolYearsSuccess = createAction(FETCH_SCHOOL_YEARS_SUCCESS, props<any>());

const FETCH_SCHOOL_YEARS_FAILURE = '[survey versions page] fetch school years failure';
export const FetchSchoolYearsFailure = createAction(FETCH_SCHOOL_YEARS_FAILURE);

/******** FETCH TIME PERIOD ***********/
const FETCH_TIME_PERIOD_REQUEST = '[survey versions page] fetch time period request';
export const FetchTimePeriodRequest = createAction(FETCH_TIME_PERIOD_REQUEST, props<{ payload: any }>());

const FETCH_TIME_PERIOD_SUCCESS = '[survey versions page] fetch time period success';
export const FetchTimePeriodSuccess = createAction(FETCH_TIME_PERIOD_SUCCESS, props<any>());

const FETCH_TIME_PERIOD_FAILURE = '[survey versions page] fetch time period failure';
export const FetchTimePeriodFailure = createAction(FETCH_TIME_PERIOD_FAILURE);

/******** FETCH SURVEY VERSIONS ***********/
const FETCH_SURVEY_VERSIONS_REQUEST = '[survey versions page] fetch survey versions request';
export const FetchSurveyVersionsRequest = createAction(FETCH_SURVEY_VERSIONS_REQUEST, props<{ payload: any }>());

const FETCH_SURVEY_VERSIONS_SUCCESS = '[survey versions page] fetch survey versions success';
export const FetchSurveyVersionsSuccess = createAction(FETCH_SURVEY_VERSIONS_SUCCESS, props<any>());

const FETCH_SURVEY_VERSIONS_FAILURE = '[survey versions page] fetch survey version failure';
export const FetchSurveyVersionsFailure = createAction(FETCH_SURVEY_VERSIONS_FAILURE);

/******** UPDATE SURVEY WINDOW ***********/
const UPDATE_SURVEY_ACCEPTING_RESPONSES_REQUEST = '[survey versions page] update survey accepting responses request';
export const UpdateSurveyAcceptingResponsesRequest = createAction(UPDATE_SURVEY_ACCEPTING_RESPONSES_REQUEST, props<{
  payload: any
}>());

const UPDATE_SURVEY_ACCEPTING_RESPONSES_SUCCESS = '[survey versions page] update survey accepting responses success';
export const UpdateSurveyAcceptingResponsesSuccess = createAction(UPDATE_SURVEY_ACCEPTING_RESPONSES_SUCCESS, props<{
  payload: {
    clientId: number
  }
}>());

const UPDATE_SURVEY_ACCEPTING_RESPONSES_FAILURE = '[survey versions page] update survey accepting responses failure';
export const UpdateSurveyAcceptingResponsesFailure = createAction(UPDATE_SURVEY_ACCEPTING_RESPONSES_FAILURE);

/******** EXPORT SURVEY DATA ***********/
const EXPORT_SURVEY_DATA_REQUEST = '[survey versions page] export survey data request';
export const ExportSurveyDataRequest = createAction(EXPORT_SURVEY_DATA_REQUEST, props<{ payload: any }>());

const EXPORT_SURVEY_DATA_SUCCESS = '[survey versions page] export survey data success';
export const ExportSurveyDataSuccess = createAction(EXPORT_SURVEY_DATA_SUCCESS);

const EXPORT_SURVEY_DATA_FAILURE = '[survey versions page] export survey data failure';
export const ExportSurveyDataFailure = createAction(EXPORT_SURVEY_DATA_FAILURE);

/******** FETCH CLIENTS FILTER ***********/
const FETCH_CLIENTS_FILTER_REQUEST = '[survey versions page] fetch clients filter request';
export const FetchClientsFilterRequest = createAction(FETCH_CLIENTS_FILTER_REQUEST);

const FETCH_CLIENTS_FILTER_SUCCESS = '[survey versions page] fetch clients filter success';
export const FetchClientsFilterSuccess = createAction(FETCH_CLIENTS_FILTER_SUCCESS, props<any>());

const FETCH_CLIENTS_FILTER_FAILURE = '[survey versions page] fetch clients filter failure';
export const FetchClientsFilterFailure = createAction(FETCH_CLIENTS_FILTER_FAILURE);

/******** FETCH_SURVEY_RESPONSES ***********/
const FETCH_SURVEY_RESPONSES_REQUEST = '[survey versions page] fetch survey responses requested';
export const FetchSurveyResponsesRequest = createAction(FETCH_SURVEY_RESPONSES_REQUEST, props<{ payload: any }>());

const FETCH_SURVEY_RESPONSES_SUCCESS = '[survey versions page] fetch survey responses successful';
export const FetchSurveyResponsesSuccess = createAction(FETCH_SURVEY_RESPONSES_SUCCESS, props<any>());

const FETCH_SURVEY_RESPONSES_FAILURE = '[survey versions page] fetch survey responses failure';
export const FetchSurveyResponsesFailure = createAction(FETCH_SURVEY_RESPONSES_FAILURE);

/******** SEND_SURVEY_EMAIL ***********/
const SEND_SURVEY_EMAIL_REQUEST = '[survey versions page] send survey email requested';
export const SendSurveyEmailRequest = createAction(SEND_SURVEY_EMAIL_REQUEST, props<{ payload: any }>());

const SEND_SURVEY_EMAIL_SUCCESS = '[survey versions page] send survey email successful';
export const SendSurveyEmailSuccess = createAction(SEND_SURVEY_EMAIL_SUCCESS);

const SEND_SURVEY_EMAIL_FAILURE = '[survey versions page] send survey email failure';
export const SendSurveyEmailFailure = createAction(SEND_SURVEY_EMAIL_FAILURE);

/******** FETCH MASTER SURVEY FILTER ***********/
const FETCH_MASTER_SURVEY_FILTER_REQUEST = '[survey versions page] fetch' +
                                           ' master survey filter request';
export const FetchMasterSurveyFilterRequest = createAction(FETCH_MASTER_SURVEY_FILTER_REQUEST, props<{ payload: any }>());

const FETCH_MASTER_SURVEY_FILTER_SUCCESS = '[survey versions page] fetch' +
                                           ' master survey filter success';
export const FetchMasterSurveyFilterSuccess = createAction(FETCH_MASTER_SURVEY_FILTER_SUCCESS, props<any>());

const FETCH_MASTER_SURVEY_FILTER_FAILURE = '[survey versions page] fetch' +
                                           ' master survey filter failure';
export const FetchMasterSurveyFilterFailure = createAction(FETCH_MASTER_SURVEY_FILTER_FAILURE);
