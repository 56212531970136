/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[manage schools of user page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** FETCH CLIENTS FILTER ***********/
const FETCH_CLIENTS_FILTER_REQUEST = '[`manage schools of user page`] fetch clients filter request';
export const FetchClientsFilterRequest = createAction(FETCH_CLIENTS_FILTER_REQUEST);

const FETCH_CLIENTS_FILTER_SUCCESS = '[manage schools of user page] fetch clients filter success';
export const FetchClientsFilterSuccess = createAction(FETCH_CLIENTS_FILTER_SUCCESS, props<any>());

const FETCH_CLIENTS_FILTER_FAILURE = '[manage schools of user page] fetch clients filter failure';
export const FetchClientsFilterFailure = createAction(FETCH_CLIENTS_FILTER_FAILURE);

/******** FETCH SCHOOL FILTER ***********/
const FETCH_SCHOOLS_FILTER_REQUEST = '[manage schools of user page] fetch schools filter request';
export const FetchSchoolsFilterRequest = createAction(FETCH_SCHOOLS_FILTER_REQUEST, props<{ payload: any }>());

const FETCH_SCHOOLS_FILTER_SUCCESS = '[manage schools of user page] fetch school filter success';
export const FetchSchoolsFilterSuccess = createAction(FETCH_SCHOOLS_FILTER_SUCCESS, props<any>());

const FETCH_SCHOOLS_FILTER_FAILURE = '[manage schools of user page] fetch schools filter failure';
export const FetchSchoolsFilterFailure = createAction(FETCH_SCHOOLS_FILTER_FAILURE);

/******** FETCH USER FILTER ***********/
const FETCH_USER_FILTER_REQUEST = '[manage schools of user page] fetch user filter request';
export const FetchUserFilterRequest = createAction(FETCH_USER_FILTER_REQUEST, props<any>());

const FETCH_USER_FILTER_SUCCESS = '[manage schools of user page] fetch user filter success';
export const FetchUserFilterSuccess = createAction(FETCH_USER_FILTER_SUCCESS, props<any>());

const FETCH_USER_FILTER_FAILURE = '[manage schools of user page] fetch user filter failure';
export const FetchUserFilterFailure = createAction(FETCH_USER_FILTER_FAILURE);

/******** FETCH SCHOOL LIST ***********/
const FETCH_SCHOOL_LIST_REQUEST = '[manage schools of user page] fetch school list request';
export const FetchSchoolListRequest = createAction(FETCH_SCHOOL_LIST_REQUEST, props<any>());

const FETCH_SCHOOL_LIST_SUCCESS = '[manage schools of user page] fetch school list success';
export const FetchSchoolListSuccess = createAction(FETCH_SCHOOL_LIST_SUCCESS, props<any>());

const FETCH_SCHOOL_LIST_FAILURE = '[manage schools of user page] fetch school list failure';
export const FetchSchoolListFailure = createAction(FETCH_SCHOOL_LIST_FAILURE);

/******** ASSIGN SCHOOL TO USER ***********/
const ASSIGN_SCHOOL_TO_USER_REQUEST = '[manage schools of user page] assign school to user request';
export const AssignSchoolToUserRequest = createAction(ASSIGN_SCHOOL_TO_USER_REQUEST, props<any>());

const ASSIGN_SCHOOL_TO_USER_SUCCESS = '[manage schools of user page] assign school to user success';
export const AssignSchoolToUserSuccess = createAction(ASSIGN_SCHOOL_TO_USER_SUCCESS, props<any>());

const ASSIGN_SCHOOL_TO_USER_FAILURE = '[manage schools of user page] assign school to user failure';
export const AssignSchoolToUserFailure = createAction(ASSIGN_SCHOOL_TO_USER_FAILURE);

/******** DISSOCIATE SCHOOL AND USER ***********/
const DISSOCIATE_SCHOOL_AND_USER_REQUEST = '[manage schools of user page] dissociate school and user request';
export const DissociateSchoolAndUserRequest = createAction(DISSOCIATE_SCHOOL_AND_USER_REQUEST, props<any>());

const DISSOCIATE_SCHOOL_AND_USER_SUCCESS = '[manage schools of user page] dissociate school and user success';
export const DissociateSchoolAndUserSuccess = createAction(DISSOCIATE_SCHOOL_AND_USER_SUCCESS, props<any>());

const DISSOCIATE_SCHOOL_AND_USER_FAILURE = '[manage schools of user page] dissociate school and user failure';
export const DissociateSchoolAndUserFailure = createAction(DISSOCIATE_SCHOOL_AND_USER_FAILURE);
