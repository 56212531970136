<div class="component-container flex flex-col justify-center items-center">
  <div
    class="content w-full flex flex-col justify-center items-center">
    <form class="flex flex-col xs:px-0 md:px-[2rem] lg:px-[8rem] w-full">
      <div *ngIf="role === roles.superAdmin"
           class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]"
      >
        <div class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]">
          Name
        </div>
        <app-text-input
          [error]="errorMessages['name']"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="name"
          placeholder="Enter School Name"
        />
      </div>
      <div *ngIf="role === roles.superAdmin"
           class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]">
        <div class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]">
          Display As
        </div>
        <app-text-input
          [error]="errorMessages['displayAs']"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="displayAs"
          placeholder="Enter Display Name"
        />
      </div>
      <div *ngIf="role === roles.superAdmin"
           class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]">
        <div class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]">
          Abbreviation
        </div>
        <app-text-input
          [error]="errorMessages['abbreviation']"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="abbreviation"
          placeholder="Enter Abbreviation"
        />
      </div>
      <div
        *ngIf="!onClientsProfilePage && role === roles.superAdmin"
        class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]"
      >
        <div class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]">
          Client
        </div>
        <app-search-on-select
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['client']"
          [loading]="clientFilterOptions.loading"
          [options]="clientFilterOptions.list"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="client"
          filterControlName="searchClient"
          placeholder="Select a client"
        ></app-search-on-select>
      </div>
      <div *ngIf="role === roles.superAdmin"
           class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]">
        <div class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]">
          Organization Type
        </div>
        <app-search-on-select
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['organizationType']"
          [loading]="organizationTypeOptions.loading"
          [options]="organizationTypeOptions.list"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="organizationType"
          filterControlName="searchOrganizationType"
          placeholder="Select Organization Type"
        ></app-search-on-select>
      </div>
      <div *ngIf="role === roles.superAdmin"
           class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]">
        <div class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]">
          School Type
        </div>
        <app-search-on-select
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['schoolType']"
          [loading]="schoolTypeOptions.loading"
          [options]="schoolTypeOptions.list"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="schoolType"
          filterControlName="searchSchoolType"
          placeholder="Select School Type"
        ></app-search-on-select>
      </div>
      <div *ngIf="role === roles.superAdmin"
           class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]">
        <div class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]">
          Product Tier
        </div>
        <app-search-on-select
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['productTier']"
          [loading]="productTierOptions.loading"
          [options]="productTierOptions.list"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="productTier"
          filterControlName="searchProductTier"
          placeholder="Select Product Tier"
        ></app-search-on-select>
      </div>
      <div *ngIf="role === roles.superAdmin"
           class="flex flex-row justify-start items-center gap-[1.5rem]">
        <div
          class="label sm:w-1/3 xs:w-1/6 flex sm:justify-end justify-start mb-[2rem]">
          Active
        </div>
        <mat-slide-toggle
          (change)="toggle($event)"
          [checked]="!!isActive"
          class="input toggle sm:w-2/3 xs:w-1/6"
        >
        </mat-slide-toggle>
      </div>
      <div
        class="flex flex-col sm:flex-row justify-start items-start gap-[2rem]">
        <div class="label sm:w-1/3 w-full flex sm:justify-end justify-start">
          Logo
        </div>
        <div class="input sm:w-2/3 w-full flex flex-col">
          <div
            class="thumbnail-section mb-[1rem] xs:flex xs:justify-start xs:items-center">
            <app-image
              [noImageForOrg]=true
              [url]="thumbnailUrl"
              class="w-[15rem] h-[15rem] block border"
            />
          </div>
          <div class="thumbnail-action-section flex flex-row gap-[1rem]">
            <app-primary-button
              (handleClick)="openFile('thumbnailUploadRef')"
              class="w-4/12"
            >
              Browse
            </app-primary-button>
            <app-text-input
              (checkForErrors)="checkForErrors('logo')"
              [error]="errorMessages['logo']"
              [parentFormGroup]="form"
              class="w-8/12"
              controlName="logo"
              placeholder="Select an Image"
            />
            <input
              #thumbnailUploadRef
              (change)="handleThumbnailUpload()"
              [id]="'thumbnailUploadRef'"
              class="input-files"
              type="file"
            />

          </div>
          <!--          DO NOT DELETE THE CODE NEEDED LATER -->
          <!--          <div class="logo-attributes">-->
          <!--            <app-checkbox-->
          <!--              [options]="checkBoxOptions"-->
          <!--              [parentFormGroup]="form"-->
          <!--              class="w-full"-->
          <!--              controlName="logoCheckbox"-->
          <!--            />-->
          <!--          </div>-->
        </div>
      </div>
      <div *ngIf="role === roles.superAdmin"
           class="sub-heading">
        State Designation
      </div>
      <div *ngIf="role === roles.superAdmin"
           class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]">
        <div class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]">
          State
        </div>
        <app-search-on-select
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['state']"
          [loading]="stateFilterOptions?.loading"
          [options]="stateFilterOptions?.list"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="state"
          filterControlName="searchState"
          placeholder="Select State"
        ></app-search-on-select>
      </div>
      <div *ngIf="role === roles.superAdmin"
           class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]">
        <div class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]">
          County
        </div>
        <app-search-on-select
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['county']"
          [loading]="countyOptions.loading"
          [options]="countyOptions.list"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="county"
          filterControlName="searchCounty"
          placeholder="Select a county"
        ></app-search-on-select>
      </div>
      <div *ngIf="role === roles.superAdmin"
           class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]">
        <div class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]">
          School District
        </div>
        <app-search-on-select
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['schoolDistrict']"
          [loading]="schoolDistrictOptions.loading"
          [options]="schoolDistrictOptions.list"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="schoolDistrict"
          filterControlName="searchSchoolDistrict"
          placeholder="Select a school District"
        ></app-search-on-select>
      </div>
      <div *ngIf="role === roles.superAdmin"
           class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]">
        <div class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]">
          School
        </div>
        <app-search-on-select
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['school']"
          [loading]="schoolFilterOptions.loading"
          [options]="schoolFilterOptions.list"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="school"
          filterControlName="searchSchool"
          placeholder="Select a School"
        ></app-search-on-select>
      </div>
    </form>
    <div class="action-section w-[80%] sm:w-1/3 flex flex-row justify-center items-center">
      <app-primary-button
        (handleClick)="save()"
        class="w-full"
      >
        Save
      </app-primary-button>
    </div>
  </div>
</div>

