import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {MenuPageComponent} from "../../shared/pages/menu-page/menu-page.component";
import {
  InternalPageNotFoundComponent
} from "../../shared/pages/page-not-found/internal-page-not-found/internal-page-not-found.component";
import {SidenavContainerComponent} from "../../shared/components/sidenav-container/sidenav-container.component";
import {MenuSidenavComponent} from "../../shared/components/menu-sidenav/menu-sidenav.component";
import {config} from "./sidenav.config";

type PathMatch = "full" | "prefix" | undefined;

const superAdminRoutes: Routes = [
  {
    path: '',
    redirectTo: 'menu',
    pathMatch: 'full'
  },
  {
    path: 'menu',
    component: MenuPageComponent,
    data: {
      administrationMenu: [
        {
          image: '/assets/images/webp/menu-user-roles.webp',
          path: '/m/manage-user-roles',
          name: 'Manage User Roles'
        },
        {
          image: '/assets/images/webp/menu-users.webp',
          path: '/m/users',
          name: 'Manage Users'
        },
        {
          image: '/assets/images/webp/menu-clients.webp',
          path: '/m/clients',
          name: 'Manage Clients'
        },
        {
          image: '/assets/images/webp/menu-surveys.webp',
          path: '/m/survey/versions',
          name: 'Manage Feedback'
        },
        {
          image: '/assets/images/webp/menu-school-years.webp',
          path: '/m/schools',
          name: 'Manage Schools'
        },
      ],
      libraryMenu: [
        {
          image: '/assets/images/webp/menu-surveys.webp',
          path: '/reports',
          name: 'Reports'
        },
        {
          image: '/assets/images/webp/menu-surveys.webp',
          path: '/downloads',
          name: 'Playbook'
        }
      ]
    }
  },
  {
    path: 'reports',
    component: SidenavContainerComponent,
    loadChildren: () =>
      import('../../page-modules/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'downloads',
    component: SidenavContainerComponent,
    loadChildren: () =>
      import('../../page-modules/downloads/downloads.module').then((m) => m.DownloadsModule),
  },
  {
    path: 'm',
    component: SidenavContainerComponent,
    children: [
      {
        path: '',
        data: {config},
        component: MenuSidenavComponent,
        outlet: 'sidenav'
      },
      {
        path: 'survey',
        children: [
          {
            path: 'versions',
            loadChildren: () => import('../../page-modules/survey-versions/survey-versions.module').then((m) => m.SurveyVersionsModule)
          },
          {
            path: 'create',
            loadChildren: () => import('../../page-modules/create-edit-copy-survey/create-edit-copy-survey.module').then((m) => m.CreateEditCopySurveyModule)
          },
          {
            path: ':surveyId/copy',
            loadChildren: () => import('../../page-modules/create-edit-copy-survey/create-edit-copy-survey.module').then((m) => m.CreateEditCopySurveyModule)
          },
          {
            path: ':surveyId/edit',
            loadChildren: () => import('../../page-modules/create-edit-copy-survey/create-edit-copy-survey.module').then((m) => m.CreateEditCopySurveyModule)
          },
        ]
      },
      {
        path: 'manage-user-roles',
        loadChildren: () => import('../../page-modules/user-roles/user-roles.module').then((m) => m.UserRolesModule)
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            pathMatch: 'full',
            loadChildren: () => import('../../page-modules/users-list/users-list.module').then((m) => m.UsersListModule)
          },
          {
            path: 'schools',
            loadChildren: () => import('../../page-modules/manage-schools-of-users/manage-schools-of-users.module').then((m) => m.ManageSchoolsOfUsersModule)
          },
        ]
      },
      {
        path: 'user-details',
        children: [
          {
            path: ':userId',
            pathMatch: 'full',
            loadChildren: () => import('../../page-modules/user-details/user-details.module').then((m) => m.UserDetailsModule)
          }
        ]
      },
      {
        path: 'school-years',
        loadChildren: () => import('../../page-modules/school-years/school-years.module').then((m) => m.SchoolYearsModule)
      },
      {
        path: 'clients',
        children: [
          {
            path: '',
            pathMatch: 'full' as PathMatch,
            loadChildren: () => import('../../page-modules/clients-list/clients-list.module').then((m) => m.ClientsListModule)
          },
          {
            path: 'schools',
            loadChildren: () => import('../../page-modules/manage-schools-of-clients/manage-schools-of-clients.module').then((m) => m.ManageSchoolsOfClientsModule)
          },
        ]
      },
      {
        path: 'client-details',
        children: [
          {
            path: ':clientId',
            pathMatch: 'full',
            loadChildren: () => import('../../page-modules/client-details/client-details.module').then((m) => m.ClientDetailsModule)
          }
        ]
      },
      {
        path: 'schools',
        loadChildren: () => import('../../page-modules/schools-list/schools-list.module').then((m) => m.SchoolsListModule)
      },
      {
        path: 'school-details',
        children: [
          {
            path: ':schoolId',
            pathMatch: 'full',
            loadChildren: () => import('../../page-modules/school-details/school-details.module').then((m) => m.SchoolDetailsModule)
          }
        ]
      },
      {
        path: 'not-found',
        children: [
          {
            path: '',
            outlet: 'content',
            component: InternalPageNotFoundComponent,
          }
        ]
      },
      {
        path: '**',
        children: [
          {
            path: '',
            outlet: 'content',
            component: InternalPageNotFoundComponent,
          }
        ]
      },
    ]
  },
  {
    path: 'not-found',
    component: InternalPageNotFoundComponent
  },
  {
    path: '**',
    component: InternalPageNotFoundComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(superAdminRoutes)],
  exports: [RouterModule]
})
export class SuperAdminRoutingModule {
}
