import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {Store} from '@ngrx/store';
import {debounceTime, Subject, takeUntil} from 'rxjs';
import {DropdownOption} from '../../../../models/dropdown-option.model';
import * as fromRoot from '../../../../state/app.state';
import {
  AddNewClientRequest,
  FetchCountyFilterRequest,
  FetchOrganizationTypeFilterRequest,
  FetchSchoolDistrictFilterRequest,
  FetchStateFilterRequest,
  UpdateClientRequest,
} from '../../core/shared.actions';
import {
  countyFilter,
  organizationTypeFilter,
  profile,
  schoolDistrictFilter,
  stateFilter,
} from '../../core/shared.selectors';
import {CommonService} from '../../services/common.service';
import {roles} from "../../constants/common.constants";

@Component({
  selector: 'app-add-or-edit-client',
  templateUrl: './add-or-edit-client.component.html',
  styleUrls: ['./add-or-edit-client.component.css'],
})
export class AddOrEditClientComponent implements OnInit, OnDestroy {
  @Input() data;
  @ViewChild('thumbnailUploadRef') thumbnailUploadRef: ElementRef;
  clientData: any;
  clientProfileDetails: any;
  errorMessages = {};
  errorMessageMap = {
    name: {
      required: 'Required',
    },
    displayAs: {
      required: 'Required',
    },
    organizationType: {
      required: 'Required',
    },
    logo: {},
    logoCheckbox: {},
    state: {
      required: 'Required',
    },
    county: {
      required: 'Required',
    },
    schoolDistrict: {
      required: 'Required',
    },
    searchState: {},
    searchCounty: {},
    searchSchoolDistrict: {},
    searchOrganizationType: {}
  };
  organizationTypeOptions = {list: [], loading: false};
  checkBoxOptions = [
    {
      key: 1,
      value: 'Display Logo on Website',
    },
    {
      key: 2,
      value: 'Display Logo on Reports',
    },
  ];
  countyOptions = {
    list: [],
    loading: false,
  };
  schoolDistrictOptions = {
    list: [],
    loading: false,
  };
  stateFilterOptions = {list: [], loading: false};
  thumbnailUrl: string = '';
  isActive: boolean = true;

  form = new FormGroup({
    name: new FormControl({value: null, disabled: false}, [
      Validators.required,
    ]),
    displayAs: new FormControl({value: null, disabled: false}, [
      Validators.required,
    ]),
    organizationType: new FormControl({value: null, disabled: false}, [
      Validators.required
    ]),
    searchOrganizationType: new FormControl({value: '', disabled: false}, []),
    logo: new FormControl({value: '', disabled: true}, []),
    logoCheckbox: new FormControl({value: null, disabled: false}, []),
    state: new FormControl({value: null, disabled: false}),
    searchState: new FormControl({value: '', disabled: false}),
    county: new FormControl({value: null, disabled: true}),
    searchCounty: new FormControl({value: '', disabled: false}),
    schoolDistrict: new FormControl({value: null, disabled: true}),
    searchSchoolDistrict: new FormControl({value: '', disabled: false}),
  });
  acceptedThumbnailFileTypes = [
    'image/jpeg', // JPG / JPEG
    'image/png', // PNG
  ];
  base64textString: String = '';
  originalSchoolDistrictOptionsList: DropdownOption[];
  originalCountyOptionsList: DropdownOption[];
  originalStateFilterOptionsList: DropdownOption[];
  originalOrganizationTypeOptionsList: DropdownOption[];
  file: any;
  role: any;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
    private cdRef: ChangeDetectorRef
  ) {
    this.store.dispatch(FetchOrganizationTypeFilterRequest({}));
    this.store.dispatch(FetchStateFilterRequest({}));

    this.store
      .select(organizationTypeFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.originalOrganizationTypeOptionsList = data?.list;
        this.organizationTypeOptions.list = data?.list;
        this.organizationTypeOptions.loading = data?.loading;
      });

    this.store
      .select(stateFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.originalStateFilterOptionsList = data?.list;
        this.stateFilterOptions.list = data?.list;
        this.stateFilterOptions.loading = data?.loading;
      });

    this.store
      .select(countyFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.countyOptions.list = data?.list;
        this.originalCountyOptionsList = data?.list;
        this.countyOptions.loading = data?.loading;
      });

    this.store
      .select(schoolDistrictFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.schoolDistrictOptions.list = data?.list;
        this.originalSchoolDistrictOptionsList = data?.list;
        this.schoolDistrictOptions.loading = data?.loading;
      });

  }

  ngOnInit() {
    this.form.controls.searchState.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.stateFilterOptions.list = this.originalStateFilterOptionsList.filter(
            (item) => {
              return item?.value?.toLowerCase().includes(data.toLowerCase());
            }
          );
        } else {
          this.stateFilterOptions.list = this.originalStateFilterOptionsList;
        }
      });

    this.form.controls.searchCounty.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.countyOptions.list = this.originalCountyOptionsList.filter(
            (item) => {
              return item?.value?.toLowerCase().includes(data.toLowerCase());
            }
          );
        } else {
          this.countyOptions.list = this.originalCountyOptionsList;
        }
      });

    this.form.controls.searchSchoolDistrict.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.schoolDistrictOptions.list =
            this.originalSchoolDistrictOptionsList.filter((item) => {
              return item?.value?.toLowerCase().includes(data.toLowerCase());
            });
        } else {
          this.schoolDistrictOptions.list =
            this.originalSchoolDistrictOptionsList;
        }
      });

    this.form.controls.searchOrganizationType.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.organizationTypeOptions.list = this.originalOrganizationTypeOptionsList.filter(
            (item) => {
              return item?.value?.toLowerCase().includes(data.toLowerCase());
            }
          );
        } else {
          this.organizationTypeOptions.list = this.originalOrganizationTypeOptionsList;
        }
      });

    const tempData = JSON.parse(JSON.stringify(this.data));
    if (tempData?.clientData) {
      this.clientData = tempData?.clientData;
      this.clientData.logoCheckbox = [
        !!this.clientData.display_logo_on_website_flag && 1,
        !!this.clientData.display_logo_on_report_flag && 2,
      ].filter(Boolean);

      if (this.clientData.state_state_code) {
        this.fetchCountyFilter(this.clientData?.state_state_code);
      }

      if (this.clientData.state_county_code) {
        this.fetchSchoolDistrictFilter(this.clientData?.state_school_code);
      }

      this.form.patchValue(
        {
          name: this.clientData?.name,
          displayAs: this.clientData?.display_label,
          organizationType: this.clientData?.client_type_id,
          logoCheckbox: this.clientData?.logoCheckbox,
          state: this.clientData?.state_state_code,
          county: this.clientData?.state_county_code,
          schoolDistrict: this.clientData?.state_district_code,
        },
        {emitEvent: false}
      );

      this.thumbnailUrl = this.clientData.logo_url;
      this.isActive = !!this.clientData.active_flag;

      this.cdRef.detectChanges();
    }

    if (tempData?.clientProfileDetails) {
      this.clientProfileDetails = tempData?.clientProfileDetails;
      this.clientProfileDetails.logoCheckbox = [
        this.clientProfileDetails.display_logo_on_website_flag && 1,
        this.clientProfileDetails.display_logo_on_report_flag && 2,
      ].filter(Boolean);

      if (this.clientProfileDetails.state_state_code) {
        this.fetchCountyFilter(this.clientProfileDetails?.state_state_code);
      }

      if (this.clientProfileDetails.state_county_code) {
        this.fetchSchoolDistrictFilter(this.clientProfileDetails?.state_school_code);
      }

      this.form.patchValue(
        {
          name: this.clientProfileDetails?.name,
          displayAs: this.clientProfileDetails?.display_label,
          organizationType: this.clientProfileDetails?.client_type_id,
          logoCheckbox: this.clientProfileDetails?.logoCheckbox,
          state: this.clientProfileDetails?.state_state_code,
          county: this.clientProfileDetails?.state_county_code,
          schoolDistrict: this.clientProfileDetails?.state_district_code,
        },
        {emitEvent: false}
      );

      this.thumbnailUrl = this.clientProfileDetails.logo_url;
      this.isActive = !!this.clientProfileDetails.active_flag;

      this.cdRef.detectChanges();
    }

    this.form.controls.state.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.fetchCountyFilter(data);
        }
      });

    this.form.controls.county.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.fetchSchoolDistrictFilter(data)
        }
      });

    this.store
      .select(profile)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.role = data?.role;
        if (this.role === roles.clientAdmin) {
          this.form.controls.name.disable();
          this.form.controls.displayAs.disable();
          this.form.controls.organizationType.disable();
          this.form.controls.state.disable();
          this.form.controls.county.disable();
          this.form.controls.schoolDistrict.disable();
        }
      });
  }

  openFile(id) {
    $(`#${id}`).click();
  }

  save() {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }

    const {
      name,
      displayAs,
      organizationType,
      state,
      county,
      schoolDistrict,
      logoCheckbox,
    } = this.form.getRawValue();

    const payload = {
      name,
      display_label: displayAs,
      client_type_id: organizationType,
      state_state_code: state,
      state_county_code: county,
      state_district_code: schoolDistrict,
      logo_file: this.file,
      active_flag: Number(!!this.isActive),
      ...(this.clientData ? {'client_id': this.clientData.id} : {}),
      ...(this.clientProfileDetails ? {'client_id': this.clientProfileDetails.id} : {}),
    };

    if (logoCheckbox?.length) {
      payload['display_logo_on_website_flag'] = Number(!!logoCheckbox.includes(1));
      payload['display_logo_on_report_flag'] = Number(!!logoCheckbox.includes(2));
    }


    if (this.clientData || this.clientProfileDetails) {
      this.store.dispatch(UpdateClientRequest({payload}));
    } else {
      this.store.dispatch(AddNewClientRequest({payload}));
    }
  }

  checkForErrors(currentField?: string) {
    this.errorMessages = {
      ...this.errorMessages,
      ...this.commonService.checkFormValidation(
        this.form,
        this.errorMessageMap,
        currentField
      ),
    };
  }

  handleThumbnailUpload() {
    this.file = this.thumbnailUploadRef.nativeElement.files[0];
    if (this.file) {
      if (!this.acceptedThumbnailFileTypes.includes(this.file?.type)) {
        this.commonService.notification('Invalid file type.', 'danger');
        return;
      }
      if (this.file.size > 5242880) {
        this.commonService.notification('File size larger than 5 mb', 'danger');
        return;
      }

      this.thumbnailUrl = URL.createObjectURL(this.file);

      this.form.controls.logo.setValue(this.file.name, {emitEvent: false});
    }

    this.thumbnailUploadRef.nativeElement.value = '';
    this.form.markAsDirty();
  }

  toggle(event: MatSlideToggleChange) {
    this.isActive = event.checked;
  }

  fetchCountyFilter(data) {
    const payload = {
      state_id: data
    }
    this.form.controls.county.enable();
    this.store.dispatch(FetchCountyFilterRequest({payload}));
  }

  fetchSchoolDistrictFilter(data) {
    const payload = {
      county_id: data
    }
    this.form.controls.schoolDistrict.enable();
    this.store.dispatch(FetchSchoolDistrictFilterRequest({payload}));
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

  protected readonly roles = roles;
}
