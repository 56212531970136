/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[manage users page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** FETCH CLIENTS FILTER ***********/
const FETCH_CLIENTS_FILTER_REQUEST = '[manage users page] fetch clients filter request';
export const FetchClientsFilterRequest = createAction(FETCH_CLIENTS_FILTER_REQUEST);

const FETCH_CLIENTS_FILTER_SUCCESS = '[manage users page] fetch clients filter success';
export const FetchClientsFilterSuccess = createAction(FETCH_CLIENTS_FILTER_SUCCESS, props<any>());

const FETCH_CLIENTS_FILTER_FAILURE = '[manage users page] fetch clients filter failure';
export const FetchClientsFilterFailure = createAction(FETCH_CLIENTS_FILTER_FAILURE);

/******** FETCH USER LIST ***********/
const FETCH_USER_LIST_REQUEST = '[manage users page] fetch user list request';
export const FetchUserListRequest = createAction(FETCH_USER_LIST_REQUEST, props<any>());

const FETCH_USER_LIST_SUCCESS = '[manage users page] fetch user list success';
export const FetchUserListSuccess = createAction(FETCH_USER_LIST_SUCCESS, props<any>());

const FETCH_USER_LIST_FAILURE = '[manage users page] fetch user list failure';
export const FetchUserListFailure = createAction(FETCH_USER_LIST_FAILURE);

/******** FETCH USER ROLES FILTER ***********/
const FETCH_USER_ROLES_FILTER_REQUEST = '[manage users page] fetch user roles filter request';
export const FetchUserRolesFilterRequest = createAction(FETCH_USER_ROLES_FILTER_REQUEST);

const FETCH_USER_ROLES_FILTER_SUCCESS = '[manage users page] fetch user roles filter success';
export const FetchUserRolesFilterSuccess = createAction(FETCH_USER_ROLES_FILTER_SUCCESS, props<any>());

const FETCH_USER_ROLES_FILTER_FAILURE = '[manage users page] fetch user roles filter failure';
export const FetchUserRolesFilterFailure = createAction(FETCH_USER_ROLES_FILTER_FAILURE);

/******** ADD NEW ADMIN ***********/
const ADD_NEW_ADMIN_REQUEST = '[manage users page] add new admin request';
export const AddNewAdminRequest = createAction(ADD_NEW_ADMIN_REQUEST, props<any>());

const ADD_NEW_ADMIN_SUCCESS = '[manage users page] add new admin success';
export const AddNewAdminSuccess = createAction(ADD_NEW_ADMIN_SUCCESS, props<any>());

const ADD_NEW_ADMIN_FAILURE = '[manage users page] add new admin failure';
export const AddNewAdminFailure = createAction(ADD_NEW_ADMIN_FAILURE);

/******** ADD NEW USER ***********/
const ADD_NEW_USER_REQUEST = '[manage users page] add new user request';
export const AddNewUserRequest = createAction(ADD_NEW_USER_REQUEST, props<any>());

const ADD_NEW_USER_SUCCESS = '[manage users page] add new user success';
export const AddNewUserSuccess = createAction(ADD_NEW_USER_SUCCESS, props<any>());

const ADD_NEW_USER_FAILURE = '[manage users page] add new user failure';
export const AddNewUserFailure = createAction(ADD_NEW_USER_FAILURE);

/******** DELETE USER ***********/
const DELETE_USER_REQUEST = '[manage users page] delete user request';
export const DeleteUserRequest = createAction(DELETE_USER_REQUEST, props<any>());

const DELETE_USER_SUCCESS = '[manage users page] delete user success';
export const DeleteUserSuccess = createAction(DELETE_USER_SUCCESS, props<any>());

const DELETE_USER_FAILURE = '[manage users page] delete user failure';
export const DeleteUserFailure = createAction(DELETE_USER_FAILURE);

/******** UPDATE USER ***********/
const UPDATE_USER_REQUEST = '[manage users page] update user request';
export const UpdateUserRequest = createAction(UPDATE_USER_REQUEST, props<any>());

const UPDATE_USER_SUCCESS = '[manage users page] update user success';
export const UpdateUserSuccess = createAction(UPDATE_USER_SUCCESS, props<any>());

const UPDATE_USER_FAILURE = '[manage users page] update user failure';
export const UpdateUserFailure = createAction(UPDATE_USER_FAILURE);
