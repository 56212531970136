<app-shimmer-loader
  *ngIf="loading"
  [appearance]="loaderAppearance"
  [count]="1"
  [theme]="loaderTheme"
  class="loader"
></app-shimmer-loader>
<img
  (error)="handleError()"
  (load)="hideLoader()"
  [class.hidden]="loading"
  [src]="url"
  class="image object-contain"
  alt=""
>
