<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="bg-transparent text-white"
>
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodePadding
    (click)="navigate(node.link)"
    class="each-item"
    [class.selected]="isSelected(node.link)"
  >
    <div
      class="flex flex-row cursor-pointer items-center justify-start px-[2rem] text-[1.2rem]"
    >
      {{node.label}}
    </div>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node
    *matTreeNodeDef="let node;when: hasChild"
    matTreeNodePadding
    class="each-group"
  >
    <div
      matTreeNodeToggle
      class="w-full flex flex-row gap-[1rem] pl-[2rem] pr-[1rem] cursor-pointer items-center text-[1.2rem] justify-between"
    >
      <div>
        {{node.label}}
      </div>
      <app-icon
        class="mat-icon-rtl-mirror"
        [icon]="treeControl.isExpanded(node) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
      >
      </app-icon>
    </div>
  </mat-tree-node>
</mat-tree>
