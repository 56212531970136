/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[user-details page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/*********** FETCH USER DETAILS **************/
const FETCH_USER_DETAILS_REQUEST = '[user-details page] fetch clients details requested';
export const FetchUserDetailsRequest = createAction(FETCH_USER_DETAILS_REQUEST, props<any>());

const FETCH_USER_DETAILS_SUCCESS = '[user-details page] fetch clients details successful';
export const FetchUserDetailsSuccess = createAction(FETCH_USER_DETAILS_SUCCESS, props<any>());

const FETCH_USER_DETAILS_FAILURE = '[user-details page] fetch clients details failure';
export const FetchUserDetailsFailure = createAction(FETCH_USER_DETAILS_FAILURE, props<any>());

/*********** FETCH SCHOOLS LIST **************/
const FETCH_SCHOOLS_LIST_REQUEST = '[user-details page] fetch schools list requested';
export const FetchSchoolsListRequest = createAction(FETCH_SCHOOLS_LIST_REQUEST, props<any>());

const FETCH_SCHOOLS_LIST_SUCCESS = '[user-details page] fetch schools list successful';
export const FetchSchoolsListSuccess = createAction(FETCH_SCHOOLS_LIST_SUCCESS, props<any>());

const FETCH_SCHOOLS_LIST_FAILURE = '[user-details page] fetch schools list failure';
export const FetchSchoolsListFailure = createAction(FETCH_SCHOOLS_LIST_FAILURE, props<any>());

/*********** FETCH ORG UNIT FILTER **************/
const FETCH_ORG_UNIT_FILTER_REQUEST = '[user-details page] fetch org unit filter requested';
export const FetchOrgUnitFilterRequest = createAction(FETCH_ORG_UNIT_FILTER_REQUEST, props<any>());

const FETCH_ORG_UNIT_FILTER_SUCCESS = '[user-details page] fetch org unit filter successful';
export const FetchOrgUnitFilterSuccess = createAction(FETCH_ORG_UNIT_FILTER_SUCCESS, props<any>());

const FETCH_ORG_UNIT_FILTER_FAILURE = '[user-details page] fetch org unit filter failure';
export const FetchOrgUnitFilterFailure = createAction(FETCH_ORG_UNIT_FILTER_FAILURE, props<any>());

/*********** ADD SCHOOL TO USER **************/
const ADD_SCHOOL_TO_USER_REQUEST = '[user-details page] add school to user requested';
export const AddSchoolToUserRequest = createAction(ADD_SCHOOL_TO_USER_REQUEST, props<any>());

const ADD_SCHOOL_TO_USER_SUCCESS = '[user-details page] add school to user successful';
export const AddSchoolToUserSuccess = createAction(ADD_SCHOOL_TO_USER_SUCCESS, props<any>());

const ADD_SCHOOL_TO_USER_FAILURE = '[user-details page] add school to user failure';
export const AddSchoolToUserFailure = createAction(ADD_SCHOOL_TO_USER_FAILURE, props<any>());

/*********** DELETE ORG UNIT OF USER **************/
const DELETE_ORG_UNIT_OF_USER_REQUEST = '[user-details page] delete org unit of user requested';
export const DeleteOrgUnitOfUserRequest = createAction(DELETE_ORG_UNIT_OF_USER_REQUEST, props<any>());

const DELETE_ORG_UNIT_OF_USER_SUCCESS = '[user-details page] delete org unit of user successful';
export const DeleteOrgUnitOfUserSuccess = createAction(DELETE_ORG_UNIT_OF_USER_SUCCESS, props<any>());

const DELETE_ORG_UNIT_OF_USER_FAILURE = '[user-details page] delete org unit of user failure';
export const DeleteOrgUnitOfUserFailure = createAction(DELETE_ORG_UNIT_OF_USER_FAILURE, props<any>());
