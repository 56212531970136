<div
  id="report_container"
  scrollToZoom
  [reportElement]="reportElement"
  [initialZoom]="initialZoom"
  (setZoomLevel)="setZoomLevel($event)"
></div>
<div
  id="download_container"
  class="hidden"
></div>
<button
  *ngIf="showDownloadButton"
  class="download-menu"
  mat-button
  [matMenuTriggerFor]="menu"
>
  <app-icon
    icon="download"
  />
</button>
<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    (click)="downloadReport('pdf')"
  >
    PDF
  </button>
  <button
    mat-menu-item
    (click)="downloadReport('xlsx')"
  >
    XLSX
  </button>
</mat-menu>
