import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import * as $ from 'jquery';
import {CommonService} from "../services/common.service";

@Directive({
  selector: '[scrollToZoom]'
})
export class ScrollToZoomDirective {
  @Input() reportElement: HTMLElement;
  @Input() initialZoom: number = 1;
  @Output() setZoomLevel: EventEmitter<any> = new EventEmitter;
  private range: number = 0.1;
  private maxZoom: number = 3;
  private minZoom: number = 0.5;

  constructor(
    private commonService: CommonService
  ) {
  }

  @HostListener("mousewheel", ["$event"]) onMousewheel(event) {
    if (this.reportElement && event.ctrlKey == true) {
      const element = $(this.reportElement);
      event.preventDefault();
      if (event.wheelDelta > 0 && this.initialZoom < this.maxZoom) {
        element.css('transition', 'all 200ms ease-in');
        element.css('transform', `scale(${this.initialZoom + this.range})`);
        this.setZoomLevel.emit(this.initialZoom + this.range);
      }
      if (event.wheelDelta < 0 && this.initialZoom > this.minZoom) {
        element.css('transition', 'all 200ms ease-out');
        element.css('transform', `scale(${this.initialZoom - this.range})`);
        this.setZoomLevel.emit(this.initialZoom - this.range);
      }

      element.hide();
      setTimeout(() => {
        element.show();
        setTimeout(() => {
          this.commonService.centerReport(this.reportElement);
        }, 100)
      }, 100);
    }
  }
}
