/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[login page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** LOGIN ***********/
const LOGIN_REQUEST = '[login page] login requested';
export const LoginRequest = createAction(LOGIN_REQUEST, props<{ payload: any }>());

const LOGIN_SUCCESS = '[login page] login successful';
export const LoginSuccess = createAction(LOGIN_SUCCESS);

const LOGIN_FAILURE = '[login page] login failure';
export const LoginFailure = createAction(LOGIN_FAILURE);

/******** ACCEPT TOS REQUEST ***********/
const ACCEPT_TOS_REQUEST = '[login page] accept tos requested';
export const AcceptTosRequest = createAction(ACCEPT_TOS_REQUEST, props<any>());

const ACCEPT_TOS_SUCCESS = '[login page] accept tos successful';
export const AcceptTosSuccess = createAction(ACCEPT_TOS_SUCCESS, props<any>());

const ACCEPT_TOS_FAILURE = '[login page] accept tos failure';
export const AcceptTosFailure = createAction(ACCEPT_TOS_FAILURE);
