/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[school details page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/*********** FETCH SCHOOL DETAILS **************/
const FETCH_SCHOOL_DETAILS_REQUEST = '[school details page] fetch clients details requested';
export const FetchSchoolDetailsRequest = createAction(FETCH_SCHOOL_DETAILS_REQUEST, props<any>());

const FETCH_SCHOOL_DETAILS_SUCCESS = '[school details page] fetch clients details successful';
export const FetchSchoolDetailsSuccess = createAction(FETCH_SCHOOL_DETAILS_SUCCESS, props<any>());

const FETCH_SCHOOL_DETAILS_FAILURE = '[school details page] fetch clients details failure';
export const FetchSchoolDetailsFailure = createAction(FETCH_SCHOOL_DETAILS_FAILURE, props<any>());

/******** FETCH USER LIST ***********/
const FETCH_USER_LIST_REQUEST = '[school details page] fetch user list request';
export const FetchUserListRequest = createAction(FETCH_USER_LIST_REQUEST, props<any>());

const FETCH_USER_LIST_SUCCESS = '[school details page] fetch user list success';
export const FetchUserListSuccess = createAction(FETCH_USER_LIST_SUCCESS, props<any>());

const FETCH_USER_LIST_FAILURE = '[school details page] fetch user list failure';
export const FetchUserListFailure = createAction(FETCH_USER_LIST_FAILURE, props<any>());

/*********** FETCH SCHOOL TYPES **************/
const FETCH_SCHOOL_TYPES_REQUEST =
  '[school details page] fetch school types requested';
export const FetchSchoolTypesRequest = createAction(
  FETCH_SCHOOL_TYPES_REQUEST,
  props<any>()
);

const FETCH_SCHOOL_TYPES_SUCCESS =
  '[school details page] fetch school types successful';
export const FetchSchoolTypesSuccess = createAction(
  FETCH_SCHOOL_TYPES_SUCCESS,
  props<any>()
);

const FETCH_SCHOOL_TYPES_FAILURE =
  '[school details page] fetch school types failure';
export const FetchSchoolTypesFailure = createAction(
  FETCH_SCHOOL_TYPES_FAILURE,
  props<any>()
);

/*********** FETCH USER FILTER **************/
const FETCH_USERS_FILTER_REQUEST = '[school details page] fetch org unit filter requested';
export const FetchUsersFilterRequest = createAction(FETCH_USERS_FILTER_REQUEST, props<any>());

const FETCH_USERS_FILTER_SUCCESS = '[school details page] fetch org unit filter successful';
export const FetchUsersFilterSuccess = createAction(FETCH_USERS_FILTER_SUCCESS, props<any>());

const FETCH_USERS_FILTER_FAILURE = '[school details page] fetch org unit filter failure';
export const FetchUsersFilterFailure = createAction(FETCH_USERS_FILTER_FAILURE, props<any>());

/*********** ADD USER TO SCHOOL **************/
const ADD_USER_TO_SCHOOL_REQUEST = '[school details page] add user to school requested';
export const AddUserToSchoolRequest = createAction(ADD_USER_TO_SCHOOL_REQUEST, props<any>());

const ADD_USER_TO_SCHOOL_SUCCESS = '[school details page] add user to school successful';
export const AddUserToSchoolSuccess = createAction(ADD_USER_TO_SCHOOL_SUCCESS, props<any>());

const ADD_USER_TO_SCHOOL_FAILURE = '[school details page] add user to school failure';
export const AddUserToSchoolFailure = createAction(ADD_USER_TO_SCHOOL_FAILURE, props<any>());

/*********** DELETE USERS OF ORG UNIT **************/
const DELETE_USERS_OF_ORG_UNIT_REQUEST = '[school details page] delete users of org unit requested';
export const DeleteUsersOfOrgUnitRequest = createAction(DELETE_USERS_OF_ORG_UNIT_REQUEST, props<any>());

const DELETE_USERS_OF_ORG_UNIT_SUCCESS = '[school details page] delete users of org unit successful';
export const DeleteUsersOfOrgUnitSuccess = createAction(DELETE_USERS_OF_ORG_UNIT_SUCCESS, props<any>());

const DELETE_USERS_OF_ORG_UNIT_FAILURE = '[school details page] delete users of org unit failure';
export const DeleteUsersOfOrgUnitFailure = createAction(DELETE_USERS_OF_ORG_UNIT_FAILURE, props<any>());
