import {Component, Input} from '@angular/core';
import {NgxSkeletonLoaderConfig} from "ngx-skeleton-loader";

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent {
  @Input() url: string = '';
  @Input() noImageForOrg: boolean = false;
  @Input() loaderAppearance: NgxSkeletonLoaderConfig["appearance"] = '';
  @Input() loaderTheme: NgxSkeletonLoaderConfig["theme"] = {};

  loading: boolean = true;

  hideLoader() {
    this.loading = false;
  }

  handleError() {
    this.hideLoader();
    this.url = this.noImageForOrg ? '/assets/images/svg/no_image.svg' : '/assets/images/webp/broken-image.webp';
  }
}
