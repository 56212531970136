import {CanActivateFn, Router} from '@angular/router';
import {CommonService} from "../services/common.service";
import {inject} from "@angular/core";

export const authenticatedGuard: CanActivateFn = (route, state) => {
  const commonService: CommonService = inject(CommonService);
  const router: Router = inject(Router);

  const authenticationToken = commonService.getAuthenticationToken();

  if (authenticationToken) {
    const tokenDetails = JSON.parse(atob(authenticationToken.split('.')[1]));

    if (
      !tokenDetails ||
      new Date(tokenDetails.exp * 1000) <= new Date(new Date().getTime())
    ) {
      commonService.logout();
      return false;
    }
  } else {
    router.navigate(['/']);
    return false;
  }


  return true;
};
