/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[manage schools of client page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** FETCH CLIENTS FILTER ***********/
const FETCH_CLIENTS_FILTER_REQUEST = '[manage schools of client page] fetch clients filter request';
export const FetchClientsFilterRequest = createAction(FETCH_CLIENTS_FILTER_REQUEST);

const FETCH_CLIENTS_FILTER_SUCCESS = '[manage schools of client page] fetch clients filter success';
export const FetchClientsFilterSuccess = createAction(FETCH_CLIENTS_FILTER_SUCCESS, props<any>());

const FETCH_CLIENTS_FILTER_FAILURE = '[manage schools of client page] fetch clients filter failure';
export const FetchClientsFilterFailure = createAction(FETCH_CLIENTS_FILTER_FAILURE);

/******** FETCH SCHOOL LIST ***********/
const FETCH_SCHOOL_LIST_REQUEST = '[manage schools of client page] fetch school list request';
export const FetchSchoolListRequest = createAction(FETCH_SCHOOL_LIST_REQUEST, props<any>());

const FETCH_SCHOOL_LIST_SUCCESS = '[manage schools of client page] fetch school list success';
export const FetchSchoolListSuccess = createAction(FETCH_SCHOOL_LIST_SUCCESS, props<any>());

const FETCH_SCHOOL_LIST_FAILURE = '[manage schools of client page] fetch school list failure';
export const FetchSchoolListFailure = createAction(FETCH_SCHOOL_LIST_FAILURE);

/******** ADD NEW SCHOOL ***********/
const ADD_NEW_SCHOOL_REQUEST = '[manage schools of client page] add new school request';
export const AddNewSchoolRequest = createAction(ADD_NEW_SCHOOL_REQUEST, props<any>());

const ADD_NEW_SCHOOL_SUCCESS = '[manage schools of client page] add new school success';
export const AddNewSchoolSuccess = createAction(ADD_NEW_SCHOOL_SUCCESS, props<any>());

const ADD_NEW_SCHOOL_FAILURE = '[manage schools of client page] add new school failure';
export const AddNewSchoolFailure = createAction(ADD_NEW_SCHOOL_FAILURE);

/******** DELETE SCHOOL ***********/
const DELETE_SCHOOL_REQUEST = '[manage schools of client page] delete school request';
export const DeleteSchoolRequest = createAction(DELETE_SCHOOL_REQUEST, props<any>());

const DELETE_SCHOOL_SUCCESS = '[manage schools of client page] delete school success';
export const DeleteSchoolSuccess = createAction(DELETE_SCHOOL_SUCCESS, props<any>());

const DELETE_SCHOOL_FAILURE = '[manage schools of client page] delete school failure';
export const DeleteSchoolFailure = createAction(DELETE_SCHOOL_FAILURE);

/******** UPDATE SCHOOL ***********/
const UPDATE_SCHOOL_REQUEST = '[manage schools of client page] update school request';
export const UpdateSchoolRequest = createAction(UPDATE_SCHOOL_REQUEST, props<any>());

const UPDATE_SCHOOL_SUCCESS = '[manage schools of client page] update school success';
export const UpdateSchoolSuccess = createAction(UPDATE_SCHOOL_SUCCESS, props<any>());

const UPDATE_SCHOOL_FAILURE = '[manage schools of client page] update school failure';
export const UpdateSchoolFailure = createAction(UPDATE_SCHOOL_FAILURE);
