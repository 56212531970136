/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[clients details page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/*********** FETCH CLIENT DETAILS **************/
const FETCH_CLIENT_DETAILS_REQUEST = '[clients details page] fetch clients details requested';
export const FetchClientDetailsRequest = createAction(FETCH_CLIENT_DETAILS_REQUEST, props<any>());

const FETCH_CLIENT_DETAILS_SUCCESS = '[clients details page] fetch clients details successful';
export const FetchClientDetailsSuccess = createAction(FETCH_CLIENT_DETAILS_SUCCESS, props<any>());

const FETCH_CLIENT_DETAILS_FAILURE = '[clients details page] fetch clients details failure';
export const FetchClientDetailsFailure = createAction(FETCH_CLIENT_DETAILS_FAILURE, props<any>());

/*********** FETCH SCHOOL FILTER **************/
const FETCH_SCHOOL_FILTER_REQUEST = '[clients details page] fetch school filter requested';
export const FetchSchoolFilterRequest = createAction(FETCH_SCHOOL_FILTER_REQUEST, props<any>());

const FETCH_SCHOOL_FILTER_SUCCESS = '[clients details page] fetch school filter successful';
export const FetchSchoolFilterSuccess = createAction(FETCH_SCHOOL_FILTER_SUCCESS, props<any>());

const FETCH_SCHOOL_FILTER_FAILURE = '[clients details page] fetch school filter failure';
export const FetchSchoolFilterFailure = createAction(FETCH_SCHOOL_FILTER_FAILURE, props<any>());

/*********** FETCH SCHOOLS LIST **************/
const FETCH_SCHOOLS_LIST_REQUEST = '[clients details page] fetch schools list requested';
export const FetchSchoolsListRequest = createAction(FETCH_SCHOOLS_LIST_REQUEST, props<any>());

const FETCH_SCHOOLS_LIST_SUCCESS = '[clients details page] fetch schools list successful';
export const FetchSchoolsListSuccess = createAction(FETCH_SCHOOLS_LIST_SUCCESS, props<any>());

const FETCH_SCHOOLS_LIST_FAILURE = '[clients details page] fetch schools list failure';
export const FetchSchoolsListFailure = createAction(FETCH_SCHOOLS_LIST_FAILURE, props<any>());

/******** FETCH USER FILTER ***********/
const FETCH_USER_FILTER_REQUEST = '[clients details page] fetch user filter request';
export const FetchUserFilterRequest = createAction(FETCH_USER_FILTER_REQUEST, props<any>());

const FETCH_USER_FILTER_SUCCESS = '[clients details page] fetch user filter success';
export const FetchUserFilterSuccess = createAction(FETCH_USER_FILTER_SUCCESS, props<any>());

const FETCH_USER_FILTER_FAILURE = '[clients details page] fetch user filter failure';
export const FetchUserFilterFailure = createAction(FETCH_USER_FILTER_FAILURE, props<any>());

/******** FETCH USER LIST ***********/
const FETCH_USER_LIST_REQUEST = '[clients details page] fetch user list request';
export const FetchUserListRequest = createAction(FETCH_USER_LIST_REQUEST, props<any>());

const FETCH_USER_LIST_SUCCESS = '[clients details page] fetch user list success';
export const FetchUserListSuccess = createAction(FETCH_USER_LIST_SUCCESS, props<any>());

const FETCH_USER_LIST_FAILURE = '[clients details page] fetch user list failure';
export const FetchUserListFailure = createAction(FETCH_USER_LIST_FAILURE, props<any>());

/******** UPDATE SCHOOL ***********/
const UPDATE_SCHOOL_REQUEST = '[clients details page] update school request';
export const UpdateSchoolRequest = createAction(
  UPDATE_SCHOOL_REQUEST,
  props<any>()
);

const UPDATE_SCHOOL_SUCCESS = '[clients details page] update school success';
export const UpdateSchoolSuccess = createAction(
  UPDATE_SCHOOL_SUCCESS,
  props<any>()
);

const UPDATE_SCHOOL_FAILURE = '[clients details page] update school failure';
export const UpdateSchoolFailure = createAction(
  UPDATE_SCHOOL_FAILURE,
  props<any>()
);
