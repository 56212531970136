import {Component} from '@angular/core';
import {Store} from "@ngrx/store";
import * as fromRoot from "../../../../../state/app.state";
import {Subject, takeUntil} from "rxjs";
import {profile} from "../../../core/shared.selectors";
import {CommonService} from "../../../services/common.service";
import {roles} from "../../../constants/common.constants";
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-authenticated-wrapper',
  templateUrl: './authenticated-wrapper.component.html',
  styleUrls: ['./authenticated-wrapper.component.css']
})
export class AuthenticatedWrapperComponent {
  superAdminMenuOptions = [
    {
      label: 'Home',
      icon: 'home',
      handleClick: () => {
        this.router.navigate(['/super-admin/menu']);
      }
    },
    {
      icon: 'profile-icon',
      key: 'profile',
      children: [
        {
          label: 'Profile',
          icon: 'account_circle',
          handleClick: () => {
            this.redirectToProfilePage();
          }
        },
        {
          label: 'Logout',
          icon: 'logout',
          handleClick: () => {
            this.commonService.logout();
          }
        }
      ]
    },
  ];
  clientAdminMenuOptions = [
    {
      label: 'Home',
      icon: 'home',
      handleClick: () => {
        this.router.navigate(['/client-admin/menu']);
      }
    },
    {
      icon: 'profile-icon',
      key: 'profile',
      children: [
        {
          label: 'Profile',
          icon: 'account_circle',
          handleClick: () => {
            this.redirectToProfilePage();
          }
        },
        {
          label: 'Logout',
          icon: 'logout',
          handleClick: () => {
            this.commonService.logout();
          }
        }
      ]
    },
  ];
  clientUserMenuOptions = [
    {
      label: 'Home',
      icon: 'home',
      handleClick: () => {
        this.router.navigate(['/client-user/menu']);
      }
    },
    {
      icon: 'profile-icon',
      key: 'profile',
      children: [
        {
          label: 'Profile',
          icon: 'account_circle',
          handleClick: () => {
            this.redirectToProfilePage();
          }
        },
        {
          label: 'Logout',
          icon: 'logout',
          handleClick: () => {
            this.commonService.logout();
          }
        }
      ]
    },
  ];

  menuOptions = [];
  role: string = ''
  displayTranslationSelector: boolean = false;
  userId: any;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.commonService
      .onNavigationEnd
      .pipe(takeUntil(this.onDestroy))
      .subscribe((d) => {
        this.displayTranslationSelector = d?.url?.includes('reports');
      });

    this.store.select(profile)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.role = data?.role;
        this.userId = data?.sub;

        this.setMenuOptions()
      })
  }

  setMenuOptions() {
    switch (this.role) {
      case roles.superAdmin:
        this.menuOptions = this.superAdminMenuOptions;
        break;
      case roles.clientAdmin:
      case roles.schoolAdmin:
        this.menuOptions = this.clientAdminMenuOptions;
        break;
      case roles.clientUser:
      case roles.schoolUser:
        this.menuOptions = this.clientUserMenuOptions;
        break;
      default:
        break;
    }
  }

  logout() {
    this.commonService.logout();
  }

  redirectToProfilePage() {
    const url = `${this.commonService.getBaseRoute()}/m/user-details/${this.userId}`;
    if (this.router.url !== url) {
      this.router.navigate(['/test'], { skipLocationChange: true }).then(() => this.router.navigate([url]))
    }
  }

  goToHome() {
    this.router.navigate([
      `/${this.commonService.getBaseRoute()}`,
    ]);
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
