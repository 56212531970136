import {createReducer, on} from '@ngrx/store';
import {
  ClearState,
  FetchMenuRequest,
  FetchMenuSuccess,
  FetchMenuFailure,
} from "./super-admin.actions";

// State for this feature (User)
export interface SuperAdminState {
  loading: boolean;
  menu: any[];
}

const initialState: SuperAdminState = {
  loading: false,
  menu: []
};

export const reducer = createReducer(
  initialState,

  // CLEAR STATE
  on(ClearState, (state) => initialState),

  // LOGIN
  on(FetchMenuRequest, (state) => ({
    ...state,
    loading: true,
    menu: []
  })),
  on(FetchMenuSuccess, (state, props) => ({
    ...state,
    loading: false,
    menu: props.menu
  })),
  on(FetchMenuFailure, (state) => ({
    ...state,
    loading: false,
    menu: []
  })),
);
