/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[super admin] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** FETCH MENU ***********/
const FETCH_MENU_REQUEST = '[super admin] fetch menu requested';
export const FetchMenuRequest = createAction(FETCH_MENU_REQUEST, props<{ payload: any }>());

const FETCH_MENU_SUCCESS = '[super admin] fetch menu successful';
export const FetchMenuSuccess = createAction(FETCH_MENU_SUCCESS, props<any>());

const FETCH_MENU_FAILURE = '[super admin] fetch menu failure';
export const FetchMenuFailure = createAction(FETCH_MENU_FAILURE);
