import {Injectable} from '@angular/core';
import {CommonService} from '../services/common.service';
import {Observable} from 'rxjs';
import {v1URL} from '../constants/urls.constants';
import {HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private commonServices: CommonService) {
  }

  refreshToken(): Observable<any> {
    const {method, url} = v1URL.login;

    let payload = new HttpParams();
    payload = payload.append(
      'refresh_token',
      this.commonServices.getRefreshToken()
    );
    payload = payload.append('grant_type', 'refresh_token');

    this.commonServices.removeAuthenticationToken();
    this.commonServices.removeRefreshToken();

    return this.commonServices.callAPI(method, url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }

  fetchCountyFilter(payload: any): Observable<any> {
    const {method, url} = v1URL.county;
    return this.commonServices.callAPI(method, url(payload));
  }

  fetchOrganizationTypeFilter(payload: any): Observable<any> {
    const {method, url} = v1URL.admin.clientTypes;
    return this.commonServices.callAPI(method, url);
  }

  fetchSchoolDistrictFilter(payload: any): Observable<any> {
    const {method, url} = v1URL.schoolDistrict;
    return this.commonServices.callAPI(method, url(payload));
  }

  fetchSchoolFilter(payload: any): Observable<any> {
    const {method, url} = v1URL.usSchool;
    return this.commonServices.callAPI(method, url(payload));
  }

  fetchStateFilter(payload: any): Observable<any> {
    const {method, url} = v1URL.state;
    return this.commonServices.callAPI(method, url);
  }

  fetchRoleFilter(payload: any): Observable<any> {
    const {method, url} = v1URL.role;
    return this.commonServices.callAPI(method, url);
  }

  fetchProductTierFilter(payload: any): Observable<any> {
    const {method, url} = v1URL.pricingTiers;
    return this.commonServices.callAPI(method, url);
  }

  updateClient(payload: any): Observable<any> {
    const {method, url} = v1URL.client.update;
    const formData: FormData = new FormData();

    payload.client_id && formData.append('id', payload.client_id);
    payload.name && formData.append('name', payload.name);
    payload.display_label && formData.append('display_label', payload.display_label);
    payload.client_type_id && formData.append('client_type_id', payload.client_type_id);
    payload.display_logo_on_website_flag && formData.append('display_logo_on_website_flag', payload.display_logo_on_website_flag);
    payload.display_logo_on_report_flag && formData.append('display_logo_on_report_flag', payload.display_logo_on_report_flag);
    payload.state_state_code && formData.append('state_state_code', payload.state_state_code);
    payload.state_county_code && formData.append('state_county_code', payload.state_county_code);
    payload.state_district_code && formData.append('state_district_code', payload.state_district_code);
    payload.active_flag && formData.append('active_flag', payload.active_flag);
    payload.logo_file && formData.append('logo_file', payload.logo_file);

    return this.commonServices.callAPI(method, url, formData, {
      headers: new HttpHeaders({})
    });
  }

  addNewClient(payload: any): Observable<any> {
    const {method, url} = v1URL.client.addNew;
    const formData: FormData = new FormData();

    payload.name && formData.append('name', payload.name);
    payload.display_label && formData.append('display_label', payload.display_label);
    payload.client_type_id && formData.append('client_type_id', payload.client_type_id);
    payload.display_logo_on_website_flag && formData.append('display_logo_on_website_flag', payload.display_logo_on_website_flag);
    payload.display_logo_on_report_flag && formData.append('display_logo_on_report_flag', payload.display_logo_on_report_flag);
    payload.state_state_code && formData.append('state_state_code', payload.state_state_code);
    payload.state_county_code && formData.append('state_county_code', payload.state_county_code);
    payload.state_district_code && formData.append('state_district_code', payload.state_district_code);
    payload.active_flag && formData.append('active_flag', payload.active_flag);
    payload.logo_file && formData.append('logo_file', payload.logo_file);

    return this.commonServices.callAPI(method, url, formData, {
      headers: new HttpHeaders({})
    });
  }

  fetchClientFilter(payload?: any): Observable<any> {
    const {method, url} = v1URL.admin.client.fetch;
    return this.commonServices.callAPI(method, url(payload));
  }

  updateUser(payload: any): Observable<any> {
    const {method, url} = v1URL.user.update;

    const formData: FormData = new FormData();

    payload.email && formData.append('email', payload.email);
    payload.user_id && formData.append('id', payload.user_id);
    payload.password && formData.append('password', payload.password);
    payload.role && formData.append('role', payload.role);
    payload.client_id && formData.append('client_id', payload.client_id);
    payload.display_name && formData.append('display_name', payload.display_name);
    payload.active_flag && formData.append('active_flag', payload.active_flag);
    payload.image_file && formData.append('image_file', payload.image_file);
    payload.accepted_TOS_flag && formData.append('accepted_TOS_flag', payload.accepted_TOS_flag);

    return this.commonServices.callAPI(method, url, formData, {
      headers: new HttpHeaders({})
    });
  }

  addNewUser(payload: any): Observable<any> {
    const {method, url} = v1URL.user.addNewUser;
    const formData: FormData = new FormData();

    payload.email && formData.append('email', payload.email);
    payload.user_id && formData.append('user_id', payload.user_id);
    payload.password && formData.append('password', payload.password);
    payload.role && formData.append('role', payload.role);
    payload.client_id && formData.append('client_id', payload.client_id);
    payload.display_name && formData.append('display_name', payload.display_name);
    payload.active_flag && formData.append('active_flag', payload.active_flag);
    payload.image_file && formData.append('image_file', payload.image_file);
    formData.append('accepted_TOS_flag', payload.accepted_TOS_flag);

    return this.commonServices.callAPI(method, url, formData, {
      headers: new HttpHeaders({})
    });
  }

  updateSchool(payload: any): Observable<any> {
    const {method, url} = v1URL.admin.updateOrganizationalUnit;
    const formData: FormData = new FormData();

    payload.schoolType && formData.append('school_type_id', payload.schoolType);
    payload.abbrev && formData.append('abbrev', payload.abbrev);
    payload.abbrev && formData.append('code', payload.abbrev);
    payload.client_id && formData.append('client_id', payload.client_id);
    payload.client_type_id && formData.append('client_type_id', payload.client_type_id);
    payload.display_logo_on_report_flag && formData.append('display_logo_on_report_flag', payload.display_logo_on_report_flag);
    payload.display_logo_on_website_flag && formData.append('display_logo_on_website_flag', payload.display_logo_on_website_flag);
    payload.label && formData.append('label', payload.label);
    formData.append('active_flag', payload.active_flag);
    payload.logo_file && formData.append('logo_file', payload.logo_file);
    payload.name && formData.append('name', payload.name);
    payload.state_code && formData.append('state_code', payload.state_code);
    payload.state_county_code && formData.append('state_county_code', payload.state_county_code);
    payload.state_district_code && formData.append('state_district_code', payload.state_district_code);
    payload.state_school_code && formData.append('state_school_code', payload.state_school_code);
    payload.organizational_unit_id && formData.append('organizational_unit_id', payload.organizational_unit_id);
    payload.school_id && formData.append('organizational_unit_id', payload.school_id);
    payload.productTier && formData.append('pricing_tier_id', payload.productTier);
    formData.append('elementary_flag', payload.elementary_flag);
    formData.append('middleschool_flag', payload.middleschool_flag);
    formData.append('highschool_flag', payload.highschool_flag);
    formData.append('desc', payload.desc);

    return this.commonServices.callAPI(method, url, formData, {
      headers: new HttpHeaders({})
    });
  }

  addNewSchool(payload: any): Observable<any> {
    const {method, url} = v1URL.admin.insertOrganizationalUnit;
    const formData: FormData = new FormData();

    payload.abbrev && formData.append('abbrev', payload.abbrev);
    payload.schoolType && formData.append('school_type_id', payload.schoolType);
    payload.abbrev && formData.append('code', payload.abbrev);
    payload.client_id && formData.append('client_id', payload.client_id);
    payload.client_type_id && formData.append('client_type_id', payload.client_type_id);
    payload.display_logo_on_report_flag && formData.append('display_logo_on_report_flag', payload.display_logo_on_report_flag);
    payload.display_logo_on_website_flag && formData.append('display_logo_on_website_flag', payload.display_logo_on_website_flag);
    payload.label && formData.append('label', payload.label);
    formData.append('active_flag', payload.active_flag);
    payload.logo_file && formData.append('logo_file', payload.logo_file);
    payload.name && formData.append('name', payload.name);
    payload.state_code && formData.append('state_code', payload.state_code);
    payload.state_county_code && formData.append('state_county_code', payload.state_county_code);
    payload.state_district_code && formData.append('state_district_code', payload.state_district_code);
    payload.state_school_code && formData.append('state_school_code', payload.state_school_code);
    payload.productTier && formData.append('pricing_tier_id', payload.productTier);
    formData.append('elementary_flag', payload.elementary_flag);
    formData.append('middleschool_flag', payload.middleschool_flag);
    formData.append('highschool_flag', payload.highschool_flag);
    formData.append('desc', payload.desc);

    return this.commonServices.callAPI(method, url, formData, {
      headers: new HttpHeaders({})
    });
  }

  fetchSchoolTypesFilter(payload?: any): Observable<any> {
    const {method, url} = v1URL.admin.schoolTypes;
    return this.commonServices.callAPI(method, url);
  }
}
