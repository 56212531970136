/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[reports] clear state';
export const ClearState = createAction(CLEAR_STATE, props<{ saveLoadingState: boolean }>());

/******** CLEAR FILTErS ***********/
const CLEAR_FILTERS = '[reports] clear filters';
export const ClearFilters = createAction(CLEAR_FILTERS);

/******** CLEAR SELECTED REPORT ***********/
const CLEAR_SELECTED_REPORT = '[reports] clear selected report';
export const ClearSelectedReport = createAction(CLEAR_SELECTED_REPORT);

/******** FETCH CLIENTS FILTER ***********/
const FETCH_CLIENTS_FILTER_REQUEST = '[reports] fetch clients filter request';
export const FetchClientsFilterRequest = createAction(FETCH_CLIENTS_FILTER_REQUEST);

const FETCH_CLIENTS_FILTER_SUCCESS = '[reports] fetch clients filter success';
export const FetchClientsFilterSuccess = createAction(FETCH_CLIENTS_FILTER_SUCCESS, props<{ list: any[] }>());

const FETCH_CLIENTS_FILTER_FAILURE = '[reports] fetch clients filter failure';
export const FetchClientsFilterFailure = createAction(FETCH_CLIENTS_FILTER_FAILURE);

/******** FETCH CATEGORIES ***********/
const FETCH_CATEGORIES_REQUEST = '[reports] fetch categories request';
export const FetchCategoriesRequest = createAction(FETCH_CATEGORIES_REQUEST, props<{ payload: any }>());

const FETCH_CATEGORIES_SUCCESS = '[reports] fetch categories success';
export const FetchCategoriesSuccess = createAction(FETCH_CATEGORIES_SUCCESS);

const FETCH_CATEGORIES_FAILURE = '[reports] fetch categories failure';
export const FetchCategoriesFailure = createAction(FETCH_CATEGORIES_FAILURE);

/******** FETCH SUBCATEGORIES ***********/
const FETCH_SUBCATEGORIES_REQUEST = '[reports] fetch subcategories request';
export const FetchSubcategoriesRequest = createAction(FETCH_SUBCATEGORIES_REQUEST, props<{ payload: any }>());

const FETCH_SUBCATEGORIES_SUCCESS = '[reports] fetch subcategories success';
export const FetchSubcategoriesSuccess = createAction(FETCH_SUBCATEGORIES_SUCCESS);

const FETCH_SUBCATEGORIES_FAILURE = '[reports] fetch subcategories failure';
export const FetchSubcategoriesFailure = createAction(FETCH_SUBCATEGORIES_FAILURE);

/******** FETCH SUBCATEGORIES ***********/
const FETCH_ITEMS_REQUEST = '[reports] fetch items request';
export const FetchItemsRequest = createAction(FETCH_ITEMS_REQUEST, props<{ payload: any }>());

const FETCH_ITEMS_SUCCESS = '[reports] fetch items success';
export const FetchItemsSuccess = createAction(FETCH_ITEMS_SUCCESS);

const FETCH_ITEMS_FAILURE = '[reports] fetch items failure';
export const FetchItemsFailure = createAction(FETCH_ITEMS_FAILURE);

/******** SET REPORTS ***********/
const SET_REPORTS = '[reports] set reports';
export const SetDashboardsList = createAction(SET_REPORTS, props<any>());

/******** FETCH SCHOOL LIST ***********/
const FETCH_SCHOOLS_LIST_REQUEST = '[reports] fetch schools list request';
export const FetchSchoolsListRequest = createAction(FETCH_SCHOOLS_LIST_REQUEST, props<{ payload: any }>());

const FETCH_SCHOOLS_LIST_SUCCESS = '[reports] fetch schools list success';
export const FetchSchoolsListSuccess = createAction(FETCH_SCHOOLS_LIST_SUCCESS, props<any>());

const FETCH_SCHOOLS_LIST_FAILURE = '[reports] fetch schools list failure';
export const FetchSchoolsListFailure = createAction(FETCH_SCHOOLS_LIST_FAILURE);

/******** FETCH SCHOOL YEARS ***********/
const FETCH_SCHOOL_YEARS_REQUEST = '[reports] fetch school years request';
export const FetchSchoolYearsRequest = createAction(FETCH_SCHOOL_YEARS_REQUEST, props<{ payload: any }>());

const FETCH_SCHOOL_YEARS_SUCCESS = '[reports] fetch school years success';
export const FetchSchoolYearsSuccess = createAction(FETCH_SCHOOL_YEARS_SUCCESS, props<any>());

const FETCH_SCHOOL_YEARS_FAILURE = '[reports] fetch school years failure';
export const FetchSchoolYearsFailure = createAction(FETCH_SCHOOL_YEARS_FAILURE);

/******** FETCH TIME PERIOD ***********/
const FETCH_TIME_PERIOD_REQUEST = '[reports] fetch time period request';
export const FetchTimePeriodRequest = createAction(FETCH_TIME_PERIOD_REQUEST, props<{ payload: any }>());

const FETCH_TIME_PERIOD_SUCCESS = '[reports] fetch time period success';
export const FetchTimePeriodSuccess = createAction(FETCH_TIME_PERIOD_SUCCESS, props<any>());

const FETCH_TIME_PERIOD_FAILURE = '[reports] fetch time period failure';
export const FetchTimePeriodFailure = createAction(FETCH_TIME_PERIOD_FAILURE);

/******** FETCH SELECTED REPORT ***********/
const FETCH_SELECTED_REPORT_REQUEST = '[reports] fetch selected report request';
export const FetchSelectedReportRequest = createAction(FETCH_SELECTED_REPORT_REQUEST, props<{ payload: any }>());

const FETCH_SELECTED_REPORT_SUCCESS = '[reports] fetch selected report success';
export const FetchSelectedReportSuccess = createAction(FETCH_SELECTED_REPORT_SUCCESS, props<any>());

const FETCH_SELECTED_REPORT_FAILURE = '[reports] fetch selected report failure';
export const FetchSelectedReportFailure = createAction(FETCH_SELECTED_REPORT_FAILURE);

/******** FETCH CLIENT VERSION ***********/
const FETCH_CLIENT_VERSION_REQUEST = '[reports] fetch client version request';
export const FetchClientVersionRequest = createAction(FETCH_CLIENT_VERSION_REQUEST, props<{ payload: any }>());

const FETCH_CLIENT_VERSION_SUCCESS = '[reports] fetch client version success';
export const FetchClientVersionSuccess = createAction(FETCH_CLIENT_VERSION_SUCCESS, props<any>());

const FETCH_CLIENT_VERSION_FAILURE = '[reports] fetch client version failure';
export const FetchClientVersionFailure = createAction(FETCH_CLIENT_VERSION_FAILURE);

/******** FETCH STAKEHOLDER ***********/
const FETCH_STAKEHOLDER_REQUEST = '[reports] fetch stakeholder request';
export const FetchStakeholderRequest = createAction(FETCH_STAKEHOLDER_REQUEST, props<{ payload: any }>());

const FETCH_STAKEHOLDER_SUCCESS = '[reports] fetch stakeholder success';
export const FetchStakeholderSuccess = createAction(FETCH_STAKEHOLDER_SUCCESS, props<any>());

const FETCH_STAKEHOLDER_FAILURE = '[reports] fetch stakeholder failure';
export const FetchStakeholderFailure = createAction(FETCH_STAKEHOLDER_FAILURE);

/******** FETCH COMBINED SCORE FILTER ***********/
const FETCH_COMBINED_SCORE_REQUEST = '[reports] fetch combined score request';
export const FetchCombinedScoreRequest = createAction(FETCH_COMBINED_SCORE_REQUEST, props<{ payload: any }>());

const FETCH_COMBINED_SCORE_SUCCESS = '[reports] fetch combined score success';
export const FetchCombinedScoreSuccess = createAction(FETCH_COMBINED_SCORE_SUCCESS, props<any>());

const FETCH_COMBINED_SCORE_FAILURE = '[reports] fetch combined score failure';
export const FetchCombinedScoreFailure = createAction(FETCH_COMBINED_SCORE_FAILURE);

/******** FETCH MASTER SURVEY FILTER ***********/
const FETCH_MASTER_SURVEY_FILTER_REQUEST = '[reports] fetch' +
                                           ' master survey filter request';
export const FetchMasterSurveyFilterRequest = createAction(FETCH_MASTER_SURVEY_FILTER_REQUEST, props<{ payload: any }>());

const FETCH_MASTER_SURVEY_FILTER_SUCCESS = '[reports] fetch' +
                                           ' master survey filter success';
export const FetchMasterSurveyFilterSuccess = createAction(FETCH_MASTER_SURVEY_FILTER_SUCCESS, props<any>());

const FETCH_MASTER_SURVEY_FILTER_FAILURE = '[reports] fetch' +
                                           ' master survey filter failure';
export const FetchMasterSurveyFilterFailure = createAction(FETCH_MASTER_SURVEY_FILTER_FAILURE);

/******** FETCH MEASURES LIST ***********/
const FETCH_MEASURES_LIST_REQUEST = '[reports] fetch measures list request';
export const FetchMeasuresListRequest = createAction(FETCH_MEASURES_LIST_REQUEST, props<any>());

const FETCH_MEASURES_LIST_SUCCESS = '[reports] fetch measures list success';
export const FetchMeasuresListSuccess = createAction(FETCH_MEASURES_LIST_SUCCESS, props<any>());

const FETCH_MEASURES_LIST_FAILURE = '[reports] fetch measures list failure';
export const FetchMeasuresListFailure = createAction(FETCH_MEASURES_LIST_FAILURE);
