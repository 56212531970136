import { Component } from '@angular/core';
import {CommonService} from "../../services/common.service";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../../../state/app.state";
import {Subject, takeUntil} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {surveyLanguageData} from "../../core/shared.selectors";
import {ClearSurveyLanguageData} from "../../core/shared.actions";

@Component({
  selector: 'app-translation-selector',
  templateUrl: './translation-selector.component.html',
  styleUrls: ['./translation-selector.component.css']
})
export class TranslationSelectorComponent {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  showGoogleTranslate = true;
  showSurveyTranslate = false;

  options = [];

  form = new FormGroup({
    language: new FormControl(null)
  })

  regex = new RegExp(/\/survey\/[a-fA-F0-9-]+/);

  enableSelector = false;

  constructor(
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    const url = this.router.url.split('?')[0];

    this.enableSelector = this.regex.test(url);

    this.store.select(surveyLanguageData)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.options = data.list;
        this.showSurveyTranslate = !!this.options?.length;
        if (!route.snapshot.queryParams['language'] && this.showSurveyTranslate) {
          this.form.patchValue({language: data.defaultLanguage});
        }


        if (!this.showSurveyTranslate) {
          this.showGoogleTranslate = data.showGoogleTranslate;
        }
      });

    this.form.controls.language
      .valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.router.navigate([], {
          queryParams: {
            ...this.route.snapshot.queryParams,
            language: data
          }
        })
      })
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
    this.store.dispatch(ClearSurveyLanguageData());
  }
}
