import {CanActivateFn, Router} from '@angular/router';
import {CommonService} from "../services/common.service";
import {inject} from "@angular/core";
import {Store} from "@ngrx/store";
import * as fromRoot from 'src/app/state/app.state';
import {profile} from "../core/shared.selectors";
import {SetProfile} from "../core/shared.actions";

export const unauthenticatedGuard: CanActivateFn = (route, state) => {
  const commonService: CommonService = inject(CommonService);
  const router: Router = inject(Router);

  if (!commonService.isAuthenticated()) {
    return true;
  } else {
    setTimeout(() => {
      router.navigate([commonService.getBaseRoute()]);
    }, 500)
  }

  return false;
};
