/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[user roles page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** FETCH USER ROLES LIST ***********/
const FETCH_USER_ROLES_LIST_REQUEST = '[user roles page] fetch user roles list request';
export const FetchUserRolesListRequest = createAction(FETCH_USER_ROLES_LIST_REQUEST);

const FETCH_USER_ROLES_LIST_SUCCESS = '[user roles page] fetch user roles list success';
export const FetchUserRolesListSuccess = createAction(FETCH_USER_ROLES_LIST_SUCCESS, props<any>());

const FETCH_USER_ROLES_LIST_FAILURE = '[user roles page] fetch user roles list failure';
export const FetchUserRolesListFailure = createAction(FETCH_USER_ROLES_LIST_FAILURE);

/******** ADD USER ROLE ***********/
const ADD_USER_ROLE_REQUEST = '[user roles page] add user role request';
export const AddUserRoleRequest = createAction(ADD_USER_ROLE_REQUEST, props<any>());

const ADD_USER_ROLE_SUCCESS = '[user roles page] add user role success';
export const AddUserRoleSuccess = createAction(ADD_USER_ROLE_SUCCESS, props<any>());

const ADD_USER_ROLE_FAILURE = '[user roles page] add user role failure';
export const AddUserRoleFailure = createAction(ADD_USER_ROLE_FAILURE);

/******** EDIT USER ROLE ***********/
const EDIT_USER_ROLE_REQUEST = '[user roles page] edit user role request';
export const EditUserRoleRequest = createAction(EDIT_USER_ROLE_REQUEST, props<any>());

const EDIT_USER_ROLE_SUCCESS = '[user roles page] edit user role success';
export const EditUserRoleSuccess = createAction(EDIT_USER_ROLE_SUCCESS, props<any>());

const EDIT_USER_ROLE_FAILURE = '[user roles page] edit user role failure';
export const EditUserRoleFailure = createAction(EDIT_USER_ROLE_FAILURE);

/******** DELETE USER ROLE ***********/
const DELETE_USER_ROLE_REQUEST = '[user roles page] delete user role request';
export const DeleteUserRoleRequest = createAction(DELETE_USER_ROLE_REQUEST, props<any>());

const DELETE_USER_ROLE_SUCCESS = '[user roles page] delete user role success';
export const DeleteUserRoleSuccess = createAction(DELETE_USER_ROLE_SUCCESS, props<any>());

const DELETE_USER_ROLE_FAILURE = '[user roles page] delete user role failure';
export const DeleteUserRoleFailure = createAction(DELETE_USER_ROLE_FAILURE);
